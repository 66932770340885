import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PushPinServices from '../../services/pushPinServices';
import { Button } from '@mui/material';
import DialogImg from './dialogImg';
import moment from 'moment';
import { width } from '@mui/system';

const NotifixList = ({ onAccep }) => {
  const [items, setItems] = useState([])
  const [item, setItem] = useState([])
  const [open, setOpen] = useState(false)
  useEffect(() => {
    setItems([])
    PushPinServices.GetAllPushPin(localStorage.getItem("token")).then((res) => {
      console.log("CheckPushPin", res)
      const data = []
      res.forEach(doc => {
        console.log('doc', new Date(doc.data().datecreate.seconds).getTime())
        data.push(doc.data())
        // setItems(previous => [...previous, doc.data()])
      });
      const sortDate = data.sort((a, b) => new Date(b.datecreate.seconds * 1000 + b.datecreate.nanoseconds / 1000000) - new Date(a.datecreate.seconds * 1000 + a.datecreate.nanoseconds / 1000000))
      setItems(sortDate)
    }).catch((error) => {
      console.log(error)
    })
  }, [])
  const handleClick = (obj) => {
    setItem(obj)
    setOpen(true)
  }
  const handleAccep = (row) => {
    if (typeof onAccep === 'function') {
      onAccep(row)
    }
  }
  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>รายการ</TableCell>
              <TableCell align="center">รายละเอียด</TableCell>
              <TableCell align="center">ระดับความสำคัญ</TableCell>
              <TableCell align="center">วันที่แจ้ง</TableCell>
              <TableCell align="center">ดูรูป</TableCell>
              <TableCell align="center">แจ้งเคส</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items && items.map((row, idx) => (
              <TableRow
                key={row.label}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.label}
                </TableCell>
                <TableCell align="center">{row.description}</TableCell>
                <TableCell align="center">{row.priority}</TableCell>
                <TableCell align="center">{
                  moment(new Date(row.datecreate.seconds * 1000 + row.datecreate.nanoseconds / 1000000)).format('Do MMMM YYYY HH:mm:ss')
                }</TableCell>
                <TableCell width={150} align="center">
                  <Button  variant="contained"
                        component="label"
                        style={{ fontSize: '10', marginBottom: 10 }}
                        sx={{
                            backgroundColor: '#fff',
                            color:'#7C7B7B',
                            borderRadius: '2rem',
                            fontWeight:'bolder',
                            fontSize:'16px',
                               '&:hover': {
                                   color: '#FF8B00',
                                   backgroundColor: '#fff',
                               }
                            
                       }} onClick={() => handleClick(row.imgs)}>ดูรูป</Button>
                </TableCell>
                <TableCell align="center">
                  {row.isAction ? <Button variant="contained" onClick={() => handleAccep(row)} color="error">รับเคสแจ้งซ่อม</Button> : <Button variant="contained" color="success" onClick={() => handleAccep(row)}>ดูข้อมูลการแจ้ง</Button>}
                </TableCell>
              </TableRow>

            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DialogImg
        open={open}
        item={item}
        onClose={(res) => setOpen(res)}
      />
    </React.Fragment>

  )
}

export default NotifixList