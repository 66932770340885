import firebase from '../config/firebaseApi'
import {
    collection,
    doc,
    setDoc,
    deleteDoc,
    onSnapshot,
    getDocs,
    addDoc,
    query,
    where,
    updateDoc,
    increment,
    orderBy,
    getDoc
} from "firebase/firestore";


import { v4 as uuidv4 } from 'uuid';
const LogHistoryService = {}
LogHistoryService.Add = (id, data) => {
    return new Promise((resolve, reject) => {
        const db = doc(firebase.firestore, "loghistory", "log", id, uuidv4())
        setDoc(db, data).then(response => resolve(response)).catch(error => reject(error))
    })
}
LogHistoryService.GetAll = (id) => {
    return new Promise((resolve, reject) => {
        const q = query(collection(firebase.firestore, "loghistory", "log", id) , orderBy("time", "desc"));
        getDocs(q).then(response => resolve(response)).catch(error => reject(error))
    })
}
export default LogHistoryService