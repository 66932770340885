import React, { useEffect, useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import OAuth from "../../config/autodeskApi";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import UploadModelService from "../../services/uploadModelService";
import { v4 as uuidv4 } from 'uuid';
import LogHistoryService from "../../services/logHistory";
import { bucketName } from "../../config/master";
import { Dialog, DialogTitle, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const LoadingModal = ({ open }) => (
  <Dialog open={open}>
    <DialogTitle style={{ textAlign: 'center' }}>
      กำลังแปลงไฟล์...
      <CircularProgress style={{ display: 'block', margin: '10px auto' }} />
    </DialogTitle>
  </Dialog>
);

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const UploadModel = ({ handleSaveUploadModel }) => {
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progress, setProgress] = useState("0");
  const [nameModel, setNameModel] = useState("");
  const [access_Token, setAccess_Token] = useState("");
  const [expires_in, setExpires_in] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState("ไม่มีการแปลงไฟล์");
  const [checkBucket, setCheckBucket] = useState(true);
  const [bucket, setBucketName] = useState(bucketName);
  const [convertData, setConvertData] = useState("");
  const [nameProject, setNameProject] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);

  useEffect(() => {
    fetchToken();
  }, []);

  const fetchToken = () => {
    OAuth.GetToken().then((response) => {
      console.log("Token response:", response);
      setAccess_Token(response.access_token);
      setExpires_in(response.expires_in);
      onCheckBucket(response.access_token);
    }).catch(error => {
      console.error("Failed to fetch token:", error);
      alert("Unable to fetch token. Please try again later.");
    });
  };

  const onCheckBucket = (data) => {
    UploadModelService.CheckBucket(data).then((response) => {
      console.log("Bucket check response:", response.data.items.length);
      if (response.data.items.length > 0) {
        setCheckBucket(true);
      } else {
        console.log('No bucket found, creating one.');
        setCheckBucket(false);
      }
    }).catch(error => {
      console.error("Failed to check bucket:", error);
      alert("Failed to check bucket. Please try again later.");
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!access_Token) {
        fetchToken();
        return;
    }

    if (selectedFiles.length === 0) {
        alert("กรุณาเลือกไฟล์ก่อนอัพโหลด");
        return;
    }

    const model_ref = uuidv4();
    const typeFile = selectedFiles[0].name.split(".");
    let formData = new FormData();
    formData.append("file", selectedFiles[0]);

    if (!checkBucket) {
        try {
            console.log("Creating bucket...");
            await UploadModelService.CreateBucket(access_Token, bucket);
            setCheckBucket(true);
        } catch (error) {
            console.error("Failed to create bucket:", error);
            alert("Failed to create bucket. Please try again later.");
            return;
        }
    }

    try {
        const uploadModel = await axios({
            method: "PUT",
            url: `https://developer.api.autodesk.com/oss/v2/buckets/${bucket}/objects/${model_ref}.${typeFile[1]}`,
            headers: {
                Authorization: `Bearer ${access_Token}`,
                "Content-Type": "application/octet-stream",
            },
            data: selectedFiles[0],
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadPercentage(percentCompleted);
                if (percentCompleted === 100) {
                    setStatus("กำลังแปลงไฟล์");
                    setShowModal(true);
                }
            },
        });

        const enurn = window.btoa(uploadModel.data.objectId);
        console.log("Get Urn Success urn:", enurn);

        const translateResponse = await UploadModelService.Translate(access_Token, enurn);
        console.log("Translate response:", translateResponse);
        setConvertData(translateResponse);

        const urn = translateResponse.data.urn;
        let check = true;

        while (check) {
            await new Promise((resolve) => setTimeout(resolve, 3000));
            const getUrnResponse = await UploadModelService.ConvertFile(access_Token, urn);
            console.log("getUrn progress:", getUrnResponse.data.progress);

            if (getUrnResponse.data.progress === "complete") {
                check = false;
                console.log("Conversion complete.");
                const currentdate = new Date();
                const data = {
                    owner: JSON.parse(localStorage.getItem("uid")).uid,
                    project_name: nameProject,
                    name_model: nameModel,
                    model_ref: model_ref,
                    token_model: "urn:" + enurn,
                    create_byname: JSON.parse(localStorage.getItem("uid")).first_name,
                    create_bylastname: JSON.parse(localStorage.getItem("uid")).last_name,
                    create_date: currentdate,
                    type_model: typeFile[1],
                    users: [JSON.parse(localStorage.getItem("uid")).uid],
                    company_id: JSON.parse(localStorage.getItem("uid")).company_id,
                };

                if (typeof handleSaveUploadModel === "function") {
                    handleSaveUploadModel(data);
                    const logData = {
                        uid: JSON.parse(localStorage.getItem("uid")).uid,
                        email: JSON.parse(localStorage.getItem("uid")).email,
                        first_name: JSON.parse(localStorage.getItem("uid")).first_name,
                        last_name: JSON.parse(localStorage.getItem("uid")).last_name,
                        time: new Date(),
                        message: "เพิ่มโมเดล",
                        model_ref: model_ref,
                        name_model: nameModel,
                    };

                    LogHistoryService.Add(JSON.parse(localStorage.getItem("uid")).company_id, logData)
                        .then((response) => {
                            console.log(response);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }

                setTimeout(() => {
                    setShowModal(false);
                    alert("แปลงไฟล์เสร็จสิ้นแล้ว");
                    navigate("/viewer");
                    setStatus("Conversion Complete");
                }, 3000);
            } else {
                setStatus("กำลังแปลงไฟล์");
                setShowModal(true);
            }
        }
    } catch (error) {
        console.error("Failed to convert or upload:", error.response ? error.response.data : error.message);
        setStatus("Error in conversion");
        setShowModal(false);
        alert("Failed to upload or convert the model. Please try again later.");
    }
  };

  return (
    <Grid container spacing={2}>
      {!checkBucket ? (
        <Grid item xs={12}>
          <TextField
            id="standard-basic"
            label="ชื่อ Bucket"
            fullWidth
            onChange={(e) => setBucketName(e.target.value)}
            value={bucket}
            sx={{
              '& label.Mui-focused': {
                color: '#FF8B00',
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: '#FF8B00',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderRadius: '2rem',
                },
                '&:hover fieldset': {
                  borderColor: '#FF8B00',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#FF8B00',
                },
                borderRadius: '2rem',
                backgroundColor: 'white',
              },
            }}
          />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <TextField
          id="standard-basic"
          label="ชื่อโครงการ"
          fullWidth
          onChange={(e) => setNameProject(e.target.value)}
          value={nameProject}
          sx={{
            '& label.Mui-focused': {
              color: '#FF8B00',
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: '#FF8B00',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderRadius: '2rem',
              },
              '&:hover fieldset': {
                borderColor: '#FF8B00',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#FF8B00',
              },
              borderRadius: '2rem',
              backgroundColor: 'white',
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="standard-basic"
          label="ชื่อโมเดล"
          fullWidth
          onChange={(e) => setNameModel(e.target.value)}
          value={nameModel}
          sx={{
            '& label.Mui-focused': {
              color: '#FF8B00',
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: '#FF8B00',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderRadius: '2rem',
              },
              '&:hover fieldset': {
                borderColor: '#FF8B00',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#FF8B00',
              },
              borderRadius: '2rem',
              backgroundColor: 'white',
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <input
          id="contained-button-file"
          type="file"
          multiple
          onChange={(e) => setSelectedFiles(e.target.files)}
          style={{ display: 'none' }}
        />
        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            component="span"
            sx={{
              backgroundColor: '#FF8B00',
              '&:hover': {
                backgroundColor: '#FF6F00',
              },
            }}
          >
            เลือกไฟล์
          </Button>
        </label>
      </Grid>
      <Grid item xs={12}>
        <LinearProgress variant="determinate" value={uploadPercentage} />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={submitHandler}
          sx={{
            backgroundColor: '#FF8B00',
            '&:hover': {
              backgroundColor: '#FF6F00',
            },
          }}
        >
          อัพโหลด
        </Button>
      </Grid>
      <LoadingModal open={showModal} />
    </Grid>
  );
};

export default UploadModel;
