import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import FreeTrialSignup from './components/freeTrial/FreeTrialSignup';
import './Css/Menubar.css';
import './App.css';

const FreeTrialApp = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/free-trial-signup" element={<FreeTrialSignup />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default FreeTrialApp;
