import React, { useState, useEffect } from 'react'
import Button from "@mui/material/Button";
import moment from 'moment'
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ManagementModelService from '../../services/managementModelService';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LogHistoryService from '../../services/logHistory';
import { firebaseName } from '../../config/master'
function Scurve() {
    const [linkUrl, setLinkUrl] = useState("")
    const [dataScurve, setDataScurve] = useState([])
    const navigate = useNavigate()
    useEffect(() => {
        ManagementModelService.GetScurve().then((res) => {
            res.forEach(e => {
                console.log("scurve_path_last", e.data().scurve_path_last)
                setLinkUrl(e.data().scurve_path_last)
                setDataScurve(e.data().scurve_path)

            });
        }).catch((error) => {
            console.log(error)
        })
    }, [])
    const onFileChange = async (e) => {
        console.log("e", e)
        const file = e.target.files[0];
        const storage = getStorage();
        const metadata = {
            contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        };
        const filename = `${moment(new Date()).format("YYYY-MM-DDTHH:mm:ss") + "name-" +JSON.parse(localStorage.getItem("uid")).first_name +"-fileName-"+ file.name}`
        const storageRef = ref(storage, `scurve/${JSON.parse(localStorage.getItem("model_detail")).model_ref}/` + filename);
        const uploadTask = uploadBytesResumable(storageRef, file, metadata);
        uploadTask.on('state_changed',
            (snapshot) => {
                console.log("snapshot", snapshot)
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                console.log(error)
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    const url = downloadURL.split("//")[1]
                    //    const newPath = url.substring(60, url.indexOf("?"))

                    const newPath = (url.split('/o/')[1]).split('?')[0]
                    console.log('aaaa', url)
                    setLinkUrl(newPath)
                    handleAddScurve(newPath)
                    var data = {
                        uid: JSON.parse(localStorage.getItem("uid")).uid,
                        email: JSON.parse(localStorage.getItem("uid")).email,
                        first_name: JSON.parse(localStorage.getItem("uid")).first_name,
                        last_name: JSON.parse(localStorage.getItem("uid")).last_name,
                        time: new Date(),
                        message: "Upload Scurve",
                        model_ref: JSON.parse(localStorage.getItem("model_detail")).model_ref,
                        name_model: JSON.parse(localStorage.getItem("model_detail")).name_model
                    }
                    LogHistoryService.Add(JSON.parse(localStorage.getItem("uid")).company_id, data).then((response) => {
                        response(response)

                    }).catch((error) => {
                        console.log(error)
                    })
                });
            }
        );
    };
    function checkIfDuplicateExists(arr) {
        return new Set(arr).size !== arr.length
    }
    const handleAddScurve = async (newPath) => {
        var data = []
        ManagementModelService.GetScurve().then(async (responseGetScurve) => {
            responseGetScurve.forEach(async (doc) => {
                if (doc.data().scurve_path) {
                    data = doc.data().scurve_path
                } else {
                    data = []
                }

            });
            if (data.length == 0 || data == null) {
                data.push(newPath)
                var dataLocation = {
                    scurve_path_last: newPath,
                    scurve_path: data,
                    model_ref: JSON.parse(localStorage.getItem("model_detail")).model_ref
                }
                ManagementModelService.AddScurve(dataLocation).then((resAddScurve) => {
                    console.log(resAddScurve)
                }).catch((error) => {
                    console.log(error)
                })
            } else {
                data.push(newPath)
                if (!checkIfDuplicateExists(data)) {
                    var dataLocation = {
                        scurve_path_last: newPath,
                        scurve_path: data,
                        model_ref: JSON.parse(localStorage.getItem("model_detail")).model_ref
                    }
                    ManagementModelService.UpdateScurve(dataLocation).then((resAddScurve) => {
                        console.log(resAddScurve)
                    }).catch((error) => {
                        console.log(error)
                    })
                }
            }
        })

    }
    const handleDownload = () => {
        window.open(`https://firebasestorage.googleapis.com/v0/b/${firebaseName.toLowerCase()}.appspot.com/o/${linkUrl}?alt=media`)

    }
    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <FormControl fullWidth sx={{
                        '& label.Mui-focused': {
                            color: '#FF8B00',
                        },
                        '& .MuiInput-underline:after': {
                            borderBottomColor: '#FF8B00',
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderRadius: '2rem',

                            },
                            '&:hover fieldset': {
                                borderColor: '#FF8B00',

                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#FF8B00',
                            },
                            borderRadius: '2rem',
                            backgroundColor: 'white',
                        },
                    }}>
                        <InputLabel id="demo-simple-select-label">เลือก Scurve</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={linkUrl}
                            style={{ backgroundColor: 'white', borderRadius: '2rem' }}
                            label="เลือก Scurve"
                            onChange={(e) => setLinkUrl(e.target.value)}
                        >
                            {dataScurve.map((value, index) => (
                                <MenuItem key={index} value={value}>{decodeURI(value.split("name-")[1])}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        variant="contained"
                        component="label"
                        style={{ fontSize: '10', marginBottom: 10, marginRight: 10 }}
                        sx={{
                            backgroundColor: '#fff',
                            color: '#7C7B7B',
                            borderRadius: '2rem',
                            fontWeight: 'bolder',
                            fontSize: '14px',
                            '&:hover': {
                                color: '#FF8B00',
                                backgroundColor: '#fff',
                            }

                        }}
                    >
                        อัพโหลดไฟล์
                        <input
                            id="upload-model"
                            name="photo"
                            type="file"
                            hidden
                            accept='.xlsx, .xls'
                            onChange={onFileChange}
                        />
                    </Button>
                    <Button
                        variant="contained"
                        component="label"
                        style={{ fontSize: '10', marginBottom: 10 }}
                        onClick={handleDownload}
                        sx={{
                            backgroundColor: '#fff',
                            color: '#7C7B7B',
                            borderRadius: '2rem',
                            fontWeight: 'bolder',
                            fontSize: '14px',
                            '&:hover': {
                                color: '#FF8B00',
                                backgroundColor: '#fff',
                            }

                        }}
                    >
                        download
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <iframe width="100%" height="720px" scrolling="no"
                        src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(`https://storage.googleapis.com/${firebaseName.toLowerCase()}.appspot.com/` + linkUrl)}&wdOrigin=BROWSELINK`}></iframe>
                </Grid>
            </Grid>

        </React.Fragment>

    )
}
//a4097a8c-033d-4145-96e9-3e8ba58ac874
//21bc1469-cdf6-4fab-80e2-10a82039c30c

export default Scurve