import React, { useState, useEffect } from 'react'
import { authConfig } from '../config/firebaseApi';
import { onAuthStateChanged } from 'firebase/auth';
import '../Css/LoadingLogo.css'
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        onAuthStateChanged(authConfig,(user) => {
            setCurrentUser(user);
            setLoading(false);
        })
    }, [])

    if (loading) {
        return (
        <div className='fetchData'style={{display:'flex' , width:'100%' , height:'100vh' , justifyContent:'center' , alignItems:'center'}}>
            <img className='logoLoading' src="Icon TIS.png" />
            <img className='logoLoadingbim'  src="Icon BIM.png" />
        </div>
        );
    }

    return (
        <AuthContext.Provider value={{ currentUser }}>
            {children}
        </AuthContext.Provider>
    )
}