import React, { useState, useEffect, useContext } from 'react'

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PushPin from './PushPin'
import Calendar from './Calendar'
import '../../Css/NotifyPopup.css'
import NotifixList from './notifixList';
import DialogAccepCase from './dialogAccepCase';

const Input = styled('input')({
    display: 'none',
});

const DialogNotiFix = ({ open, handleSave, handleClose, onSubmit }) => {
    const [value, setValue] = useState('1');
    const [data, setData] = useState(null)
    const [label, setLabel] = useState("")
    const [description, setDescription] = useState("")
    const [createDate, setCreateDate] = useState(new Date());
    const [priority, setPriority] = useState("")
    const [FileUpload, setFileUpload] = useState([])
    const [formData, setFormData] = useState(null)
    const [openAccepCase, setOpenAccepCase] = useState(false)
    const [item, setItem] = useState(undefined)
    const handleClickClose = () => {
        if (typeof handleClose === 'function') {
            handleClose(false)
        }
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeDate = (newValue) => {
        setCreateDate(newValue);
    };
    const handleOnSave = (obj) => {
        if (typeof handleSave === "function") {
            handleSave(obj)
        }

    }
    const handleOpenDialog = (row) => {
        setOpenAccepCase(true)
        setItem(row)
    }
    return (
        <React.Fragment>
            <Dialog fullWidth maxWidth="md" open={open} onClose={handleClickClose} >
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example" sx={{
                                '& .MuiTabs-indicator': {
                                    backgroundColor: '#FF8B00',
                                },
                                '& .MuiTabs-flexContainer ': {

                                    '& .Mui-selected': {
                                        color: '#FF8B00',

                                    },
                                }
                            }}>
                                <Tab label="แบบฟอร์มแจ้งซ่อม" value="1" />
                                <Tab label="ปฏิทินการซ่อม" value="2" />
                                <Tab label="ตารางการแจ้งซ่อม" value="3" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <DialogTitle style={{ textAlign: 'center', color: '#9F5700', fontWeight: 'bold', paddingBottom: '0', paddingTop: '0' }}>ระบบรายการแจ้งซ่อม</DialogTitle>
                            <DialogContent key={`dialogworker-dd`}></DialogContent>
                            <PushPin
                                handleSave={handleOnSave}
                            />
                        </TabPanel>
                        <TabPanel value="2" style={{ backgroundColor: '#fff' }}>
                            <Calendar />
                        </TabPanel>
                        <TabPanel value="3" style={{ backgroundColor: '#fff' }}>
                            <NotifixList onAccep={handleOpenDialog} />
                        </TabPanel>
                    </TabContext>
                </Box>

            </Dialog>
            <DialogAccepCase open={openAccepCase} onClose={(e) => setOpenAccepCase(e)} data={item} onSubmit={onSubmit}/>
        </React.Fragment>

    )
}

export default DialogNotiFix