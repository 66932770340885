import firebase from '../config/firebaseApi'
import { collection, query, where, getDocs, doc, setDoc, getDoc, updateDoc } from "firebase/firestore";

const ManagementUserService = {}
ManagementUserService.AddUser = (uid, dataUser) => {
    return new Promise((resolve, reject) => {
        const db = doc(firebase.firestore, "users", uid)
        setDoc(db, dataUser)
            .then(response => resolve(response))
            .catch(error => reject(error))
    })
}
ManagementUserService.GetUserInfo = (uid) => {
    return new Promise((resolve, reject) => {
        const q = query(collection(firebase.firestore, "users"), where("uid", "==", uid));
        getDocs(q).then(response => resolve(response)).catch(error => reject(error))
    })
}
ManagementUserService.GetUserInfoOnce = (uid) => {
    return new Promise((resolve, reject) => {
        const docRef = doc(firebase.firestore, "users", uid);
        getDoc(docRef).then(response => resolve(response)).catch(error => reject(error))
    })
}
ManagementUserService.UpdateCompany = (uid, data) => {
    return new Promise((resolve, reject) => {
        const modelRef = doc(firebase.firestore, "users", uid);
        updateDoc(modelRef, data).then(response => resolve(response)).catch(error => reject(error))
    })
}
ManagementUserService.GetAllUserInCompany = (company_id) => {
    return new Promise((resolve, reject) => {
        const q = query(collection(firebase.firestore, "users"), where("company_id", "==", company_id));
        getDocs(q).then(response => resolve(response)).catch(error => reject(error))
    })
}
ManagementUserService.UpdateRole = (uid, data) => {
    return new Promise((resolve, reject) => {
        const docRef = doc(firebase.firestore, "users", uid);
        updateDoc(docRef, data).then((response) => resolve(response)).catch((error) => reject(error))
    })
}
ManagementUserService.UpdateImg = (uid, data) => {
    return new Promise((resolve, reject) => {
        const docRef = doc(firebase.firestore, "users", uid);
        updateDoc(docRef, data).then((response) => resolve(response)).catch((error) => reject(error))
    })
}
// เพิ่มใน ManagementUserService
ManagementUserService.countUsersByCompany = (companyId) => {
    return new Promise((resolve, reject) => {
        const q = query(collection(firebase.firestore, "users"), where("company_id", "==", companyId));
        getDocs(q).then(snapshot => {
            resolve(snapshot.size); // คืนค่าจำนวน documents ที่พบ
        }).catch(error => reject(error));
    });
};

export default ManagementUserService;


