import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import ManagementModelService from '../../services/managementModelService';
import LogHistoryService from '../../services/logHistory';
import Swal from 'sweetalert2'

const MapModel = () => {
    const [lat, setLat] = React.useState(0)
    const [lon, setLon] = React.useState(0)
    const [name_model, setName_model] = useState("")
    useEffect(() => {
        onFetchModel()
    }, [])
    const onFetchModel = () => {
        ManagementModelService.GetModel(localStorage.getItem('token')).then((response) => {
            response.forEach(e => {
                console.log('55', e.data().name_model)
                setName_model(e.data().name_model)
                setLat(e.data().lat)
                setLon(e.data().lon)
            });
           
        }).catch((error) => {
            console.log(error)
        })
    }
    const handleOnSave = () => {
        var data1 = {
            lat: lat,
            lon: lon
        }
        console.log('m',name_model)
        ManagementModelService.UpdateMap(name_model,data1).then((response) => {
            console.log('m res',response)
            var data={
                uid:JSON.parse(localStorage.getItem("uid")).uid,
                email:JSON.parse(localStorage.getItem("uid")).email,
                first_name:JSON.parse(localStorage.getItem("uid")).first_name,
                last_name:JSON.parse(localStorage.getItem("uid")).last_name,
                time:new Date(),
                message:"อัพเดตแผนที่",
                model_ref:JSON.parse(localStorage.getItem("model_detail")).model_ref,
                name_model:JSON.parse(localStorage.getItem("model_detail")).name_model
            }
            LogHistoryService.Add(JSON.parse(localStorage.getItem("uid")).company_id,data).then((response)=>{
                Swal.fire({
                    title: 'อัพเดตสำเร็จ',
                    text: `อัพเดตข้อมูลแผนที่สำเร็จ`,
                    icon: 'success',
                    confirmButtonText: 'รับทราบ'
                })
                response(response)
                
              
            }).catch((error)=>{
                console.log(error)
            })
        }).catch((error) => {
            console.log(error)
        })
    }
    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <InputLabel style={{ marginBottom: '0.5rem', color: '#6F6C6C', fontWeight: 'bolder', marginLeft: '0.5rem', textAlign: 'center' }}>ละติจูด</InputLabel>
                    <TextField id="outlined-basic" fullWidth label="ระบุละติจูด" variant="outlined" color='primary' 
                    sx={{
                        '& label.Mui-focused': {
                            color: '#FF8B00',
                        },
                        '& .MuiInput-underline:after': {
                            borderBottomColor: '#FF8B00',
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderRadius: '2rem',
                                
                            },
                            '&:hover fieldset': {
                                borderColor: '#FF8B00',
                                
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#FF8B00',
                            },
                            borderRadius: '2rem',
                            backgroundColor: 'white',
                        },
                    }} onChange={(e)=>setLat(e.target.value)}/>

                </Grid>
                <Grid item xs={12} md={4}>
                    <InputLabel style={{ marginBottom: '0.5rem', color: '#6F6C6C', fontWeight: 'bolder', marginLeft: '0.5rem', textAlign: 'center' }}>ลองจิจูด</InputLabel>
                    <TextField id="outlined-basic" fullWidth label="ระบุลองจิจูด" variant="outlined" color='primary' sx={{
                    '& label.Mui-focused': {
                        color: '#FF8B00',
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: '#FF8B00',
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderRadius: '2rem',
                            
                        },
                        '&:hover fieldset': {
                            borderColor: '#FF8B00',
                            
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#FF8B00',
                        },
                        borderRadius: '2rem',
                        backgroundColor: 'white',
                    },
                }}
                onChange={(e)=>setLon(e.target.value)}
                />
                </Grid>
                <Grid item xs={12} md={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                    <Button
                        variant="contained"
                        component="label"
                        style={{ fontSize: '10', marginBottom: 10 }}
                        sx={{
                            backgroundColor: '#fff',
                            color:'#7C7B7B',
                            borderRadius: '2rem',
                            fontWeight:'bolder',
                            fontSize:'16px',
                               '&:hover': {
                                   color: '#FF8B00',
                                   backgroundColor: '#fff',
                               }
                            
                       }}
                     onClick={handleOnSave}
                    >
                        ยืนยันตำแหน่ง
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {/* <iframe width="100%" height="735px" scrolling="no"
                        src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent("https://storage.googleapis.com/bimiot-98.appspot.com/" + linkUrl)}&wdOrigin=BROWSELINK`}></iframe> */}
                    <iframe
                        height="650px" width="100%"
                        frameBorder="0"
                        loading="lazy"
                        src={`https://maps.google.com/maps?q=${lat},${lon}&output=embed`}
                    >
                    </iframe>
                </Grid>
            </Grid>


        </React.Fragment>
    )
}

export default MapModel