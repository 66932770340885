import React, { useState, useEffect, useContext } from 'react'
import Dialog from '@mui/material/Dialog';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import '../../Css/NotifyPopup.css'
import Scurve from './scurve';
import MapModel from './mapModel';
import Docs from './docs';
import ModelDetail from '../modDetail/modelDetail';
function DialogOverAll({ open, handleSave, handleClose }) {
    const [value, setValue] = useState('3');
    const [data, setData] = useState(null)
    const [label, setLabel] = useState("")
    const [description, setDescription] = useState("")
    const [createDate, setCreateDate] = useState(new Date());
    const handleClickClose = () => {
        if (typeof handleClose === 'function') {
            handleClose(false)
        }
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeDate = (newValue) => {
        setCreateDate(newValue);
    };
    const handleOnSave = (obj) => {
        if (typeof handleSave === "function") {
            handleSave(obj)
        }

    }
    return (
        <Dialog fullWidth maxWidth="xlg" open={open} onClose={handleClickClose} >
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" sx={{
                            '& .MuiTabs-indicator': {
                                backgroundColor: '#FF8B00',
                            },
                            '& .MuiTabs-flexContainer ': {

                                '& .Mui-selected': {
                                    color: '#FF8B00',

                                },
                            }
                        }}>
                            {JSON.parse(localStorage.getItem("uid")).role !== 1 ? <Tab label="S-Curve" value="1" /> : null}
                            {JSON.parse(localStorage.getItem("uid")).role !== 1 ? <Tab label="เอกสารโครงการ" value="2" /> : null}


                            <Tab label="ทำเลที่ตั้ง" value="3" />
                            {localStorage.getItem("dataModel") && <Tab label="รายละเอียดโมเดล" value="4" />}
                        </TabList>
                    </Box>
                    {JSON.parse(localStorage.getItem("uid")).role !== 1 ?
                        <TabPanel value="1">
                            <Scurve />
                        </TabPanel> :
                        null}

                    {JSON.parse(localStorage.getItem("uid")).role !== 1 ?
                        <TabPanel value="2">
                            <Docs />
                        </TabPanel> :
                        null}

                    <TabPanel value="3">
                        <MapModel />
                    </TabPanel>
                    {localStorage.getItem("dataModel") && <TabPanel value="4">
                        <ModelDetail />
                    </TabPanel>}
                </TabContext>
            </Box>
        </Dialog>
    )
}

export default DialogOverAll