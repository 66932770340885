import firebase from '../config/firebaseApi';
import {
  doc,
  setDoc,
  getDoc,
  collection,
  query,
  orderBy,
  getDocs
} from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';

const ChatServices = {};

ChatServices.Send = async (data) => {
  const docRef = doc(firebase.firestore, "users", data.sender);
  const docSnap = await getDoc(docRef);

  const dataChat = {
    ...data,
    name: docSnap.data().first_name
  };

  return new Promise((resolve, reject) => {
    const db = doc(firebase.firestore, "chats", "Models", JSON.parse(localStorage.getItem("model_detail")).model_ref, uuidv4());
    setDoc(db, dataChat).then(response => resolve(response)).catch(error => reject(error));
  });
};

ChatServices.GetAll = () => {
  return new Promise((resolve, reject) => {
    const q = query(collection(firebase.firestore, "chats", "Models", JSON.parse(localStorage.getItem("model_detail")).model_ref), orderBy("sentTime", "asc"));
    getDocs(q).then(response => resolve(response)).catch(error => reject(error));
  });
};

export default ChatServices;
