import { collection, query, where, getDocs, whereArrayContains } from "firebase/firestore";
import firebase from '../config/firebaseApi'
const QueryModelsService = {}
QueryModelsService.GetModelSelect = () => {
    return new Promise((resolve, reject) => {
        const q = query(collection(firebase.firestore, "models"), where("company_id", "==", JSON.parse(localStorage.getItem("uid")).company_id));
        getDocs(q).then(response => resolve(response)).catch(error => reject(error))
    })
}
// QueryModelsService.GetModelSelect = () => {
//     return new Promise((resolve, reject) => {
//         const q = query(collection(firebase.firestore, "models"), where("users", "array-contains", JSON.parse(localStorage.getItem("uid")).uid));
//         getDocs(q).then(response => resolve(response)).catch(error => reject(error))
//     })
// }
export default QueryModelsService