import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import ModelDetailList from './modelDetailList'
import ModelDetaiExample from './ModelDetaiExample'
import { InputLabel } from '@mui/material'
import ModelDetailServices from '../../services/modelDetailServices'
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Swal from 'sweetalert2'
import LogHistoryService from '../../services/logHistory'
import ModelDetailEditList from './modelDetailEditList'
import { v4 as uuidv4 } from 'uuid';
import { previousDay } from 'date-fns'
const ModelDetail = () => {
    const [data, setData] = useState(null)
    const [catagory, setCatagory] = useState("")
    const [field, setField] = useState("")
    const [value, setValue] = useState("")
    const [items, setItems] = useState([])
    useEffect(() => {
        if (localStorage.getItem("dataModel")) {
            setData(JSON.parse(localStorage.getItem("dataModel")).data)
        } else {
            Swal.fire({
                title: 'ผิดพลาด',
                text: 'กรุณาเลือกชิ้นส่วนก่อน',
                icon: 'error',
                confirmButtonText: 'รับทราบ'
            })
        }
        setItems(JSON.parse(localStorage.getItem("detailItem"))?.data)
        return () => {

        }
    }, [])

    const handleOnSave = () => {
        if (catagory === "" || field === "" || value === "") {
            return Swal.fire({
                title: 'ผิดพลาด',
                text: 'กรุณากรอกข้อมูลให้ครบถ้วน',
                icon: 'error',
                confirmButtonText: 'รับทราบ'
            })
        }
        const dataJson = {
            id: uuidv4(),
            dbId: data.dbId,
            displayCategory: catagory,
            displayName: field,
            displayValue: value,
            model_ref: JSON.parse(localStorage.getItem("model_detail")).model_ref
        }
        setItems(previous => [...previous, {
            id: dataJson.id,
            field: dataJson.displayName,
            value: dataJson.displayValue,
            catagory: dataJson.displayCategory
        }])
        ModelDetailServices.Add(dataJson).then((res) => {
            var data = {
                uid: JSON.parse(localStorage.getItem("uid")).uid,
                email: JSON.parse(localStorage.getItem("uid")).email,
                first_name: JSON.parse(localStorage.getItem("uid")).first_name,
                last_name: JSON.parse(localStorage.getItem("uid")).last_name,
                time: new Date(),
                message: "เพิ่มรายละเอียดโมเดล",
                model_ref: JSON.parse(localStorage.getItem("model_detail")).model_ref,
                name_model: JSON.parse(localStorage.getItem("model_detail")).name_model
            }
            LogHistoryService.Add(JSON.parse(localStorage.getItem("uid")).company_id, data).then((response) => {
                Swal.fire({
                    title: 'อัพเดตสำเร็จ',
                    text: `อัพเดตข้อมูลไอดี${dataJson.dbId} สำเร็จ`,
                    icon: 'success',
                    confirmButtonText: 'รับทราบ'
                })

            }).catch((error) => {
                console.log(error)
            })
        }).catch((error) => {
            console.log(error)
        })
    }
    const handleDelete=(row)=>{
        ModelDetailServices.Delete(row.id).then((response)=>{
            console.log('handleDelete',response)
        }).catch((error)=>{
            console.log(error)
        })
        setItems(previous=>previous.filter((i)=>i.id!==row.id))
    }
    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid item md={6} xs={12} style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <DialogTitle style={{ textAlign: 'center', color: '#9F5700', fontWeight: 'bold', paddingBottom: '0', paddingTop: '0', marginBottom: '1.5rem' }}>แก้ไขรายละเอียดโมเดล</DialogTitle>
                    <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <InputLabel style={{ marginBottom: '1rem', color: '#6F6C6C', fontWeight: 'bolder', marginLeft: '0.5rem' }}>ชื่อ Catagory</InputLabel>
                                <TextField fullWidth id="outlined-basic" variant="outlined" color='primary' label="ระบุชื่อ Catagory" onChange={(e) => setCatagory(e.target.value)} sx={{
                                    '& label.Mui-focused': {
                                        color: '#FF8B00',
                                    },
                                    '& .MuiInput-underline:after': {
                                        borderBottomColor: '#FF8B00',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderRadius: '2rem',

                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#FF8B00',

                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#FF8B00',
                                        },
                                        borderRadius: '2rem',
                                        backgroundColor: 'white',
                                    },
                                }} />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel style={{ marginBottom: '1rem', color: '#6F6C6C', fontWeight: 'bolder', marginLeft: '0.5rem' }}>ชื่อ Field ใน Catagory</InputLabel>
                                <TextField fullWidth id="outlined-basic" variant="outlined" color='primary' label="ระบุชื่อ Field" onChange={(e) => setField(e.target.value)} sx={{
                                    '& label.Mui-focused': {
                                        color: '#FF8B00',
                                    },
                                    '& .MuiInput-underline:after': {
                                        borderBottomColor: '#FF8B00',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderRadius: '2rem',

                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#FF8B00',

                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#FF8B00',
                                        },
                                        borderRadius: '2rem',
                                        backgroundColor: 'white',
                                    },
                                }} />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel style={{ marginBottom: '1rem', color: '#6F6C6C', fontWeight: 'bolder', marginLeft: '0.5rem' }}>ค่า Value ใน Field</InputLabel>
                                <TextField fullWidth id="outlined-basic" variant="outlined" color='primary' label="ระบุค่า value" onChange={(e) => setValue(e.target.value)} sx={{
                                    '& label.Mui-focused': {
                                        color: '#FF8B00',
                                    },
                                    '& .MuiInput-underline:after': {
                                        borderBottomColor: '#FF8B00',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderRadius: '2rem',

                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#FF8B00',

                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#FF8B00',
                                        },
                                        borderRadius: '2rem',
                                        backgroundColor: 'white',
                                    },
                                }} />
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {/* <Button variant='contained' color="success" onClick={handleOnSave}>บันทึกการเปลี่ยนแปลง</Button> */}
                                <Button variant="contained" className='px-4' onClick={handleOnSave} sx={{
                                    backgroundColor: '#fff',
                                    color: '#7C7B7B',
                                    borderRadius: '2rem',
                                    fontWeight: 'bolder',
                                    fontSize: '16px',
                                    '&:hover': {
                                        color: '#FF8B00',
                                        backgroundColor: '#fff',
                                    }

                                }}>บันทึกการเปลี่ยนแปลง</Button>
                            </Grid>
                        </Grid>
                    </div>

                </Grid>
                <Grid item md={6} xs={12}>
                    <InputLabel style={{ marginBottom: '1rem', color: '#6F6C6C', fontWeight: 'bolder', marginLeft: '0.5rem' }}>ตัวอย่าง Properties</InputLabel>
                    <ModelDetaiExample Catagory={catagory} Field={field} Value={value} />
                </Grid>
                <Grid item md={12} xs={12}>
                    <ModelDetailList data={data} />
                </Grid>
                <Grid item md={12} xs={12}>
                    <ModelDetailEditList data={items} onDelete={handleDelete}/>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default ModelDetail