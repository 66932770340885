import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import AccepCaseList from './AccepCaseList';
import Grid from '@mui/material/Grid';
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { InputLabel } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import { styled } from '@mui/material/styles';
const Input = styled('input')({
    display: 'none',
});

const DialogAccepCase = ({ open, onClose, data, onSubmit }) => {
    const [nameCase, setNameCase] = useState("")
    const [items, setItems] = useState([])
    const [imgs, setImgs] = useState([])
    const [description, setDescription] = useState("")

    const handleAdd = () => {
        setItems(previous => [...previous, {
            sequent: items.length + 1,
            name: "",
            qty: "",
            price: "",
        }])
    }
    const handleClose = () => {
        if (typeof onClose === 'function') {
            onClose(false)
        }
    }
    useEffect(() => {
        if (data?.isAction === false) {
            setItems(data?.items)
            setImgs(data?.billUrl)
            setDescription(data?.description_ac)
        } else {
            setItems([])
            setImgs([])
            setDescription('')
        }
        console.log('data 55555', data)
    }, [open, data])
    const handleOnSave = (row) => {
        setItems(previous => previous.map(p => {
            if (p.sequent === row.sequent) {
                return {
                    ...p,
                    name: row.name,
                    qty: row.qty,
                    price: row.price,
                }
            } else {
                return p
            }
        }))
    }
    const handleSubmit = () => {
        var dataItems = {
            description_ac: description,
            items: items,
            billUrl: imgs,
            user_accep_id: JSON.parse(localStorage.getItem("uid")).uid,
            user_accep_name: JSON.parse(localStorage.getItem("uid")).first_name,
            isAction: false
        }
        if (typeof onSubmit === 'function') {
            onSubmit(dataItems, data)
            handleClose()
        }
    }
    const handleDelete = (row) => {
        setItems(previous => previous.filter((i) => i.sequent !== row.sequent))
    }

    const onFileChange = async (e) => {
        const url = []
        const file = e.target.files[0];
        const storage = getStorage();
        const metadata = {
            contentType: 'image/jpeg'
        };
        const filename = `${"name-" + file.name}`

        const storageRef = ref(storage, `images/pushpin_bill/${data?.id}/` + filename);
        const uploadTask = uploadBytesResumable(storageRef, file, metadata);
        uploadTask.on('state_changed',
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                switch (error.code) {
                    case 'storage/unauthorized':
                        break;
                    case 'storage/canceled':
                        break;
                    case 'storage/unknown':
                        break;
                }
            },
            () => {

                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    setImgs(previous => [...previous, downloadURL])
                });


            },
        );


    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            // style={{ width: '100%' }}
            fullWidth maxWidth="md"
        >
            <DialogTitle id="alert-dialog-title">
                {"รายงานการซ่อมบำรุง"}
            </DialogTitle>
            <DialogContent>
                <div style={{display:'flex' , width:'100%' , justifyContent:'space-between'}}>
                    <div style={{ display: 'flex', marginBottom: '1rem' }}>
                        <div>
                            <InputLabel style={{ color: '#6F6C6C', fontWeight: 'bolder', marginLeft: '0.5rem' }}>ชื่อรายการ</InputLabel>
                            <TextField id="outlined-basic" sx={{
                                '& label.Mui-focused': {
                                    color: '#FF8B00',
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: '#FF8B00',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderRadius: '2rem',

                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#FF8B00',

                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#FF8B00',
                                    },
                                    borderRadius: '2rem',
                                    backgroundColor: 'white',
                                },
                            }} variant="outlined" color='primary' disabled={true} value={data?.label} />
                        </div>
                        <div>
                            <InputLabel style={{ color: '#6F6C6C', fontWeight: 'bolder', marginLeft: '0.5rem' }}>รายละเอียดการแจ้งซ่อม</InputLabel>
                            <TextField id="outlined-basic" sx={{
                                '& label.Mui-focused': {
                                    color: '#FF8B00',
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: '#FF8B00',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderRadius: '2rem',

                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#FF8B00',

                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#FF8B00',
                                    },
                                    borderRadius: '2rem',
                                    backgroundColor: 'white',
                                },
                            }} variant="outlined" color='primary' disabled={!data?.isAction} value={description} onChange={(e) => setDescription(e.target.value)} />
                        </div>
                    </div>
                    <div style={{alignSelf:'center'}}>
                    {
                        data?.isAction && <Button
                            variant="contained"
                            component="label"
                            style={{ fontSize: '10' }}
                            sx={{
                                backgroundColor: '#fff',
                                color: '#7C7B7B',
                                borderRadius: '2rem',
                                fontWeight: 'bolder',
                                fontSize: '16px',
                                '&:hover': {
                                    color: '#FF8B00',
                                    backgroundColor: '#fff',
                                }

                            }}
                            onClick={handleAdd} color="inherit">เพิ่มข้อมูล</Button>
                    }
                    </div>
                    
                </div>


                <div style={{ marginBottom: '1rem' }}>
                    <AccepCaseList data={items} onSave={handleOnSave} onDelete={handleDelete} onStatus={data?.isAction} />
                </div>
                {
                    data?.isAction && <label htmlFor="contained-button-file" >
                        <Input accept="image/*" id="contained-button-file" multiple type="file" onChange={(e) => onFileChange(e)} />
                        <Button fullWidth variant="contained" component="span" sx={{
                            backgroundColor: '#fff',
                            color: '#7C7B7B',
                            borderRadius: '2rem',
                            fontWeight: 'bolder',
                            fontSize: '16px',
                            '&:hover': {
                                color: '#FF8B00',
                                backgroundColor: '#fff',
                            }

                        }}>
                            อัพโหลดรูป
                        </Button>
                    </label>

                }

                {
                    imgs && imgs.length === 0 ? <h3 style={{ textAlign: 'center' }}>ไม่มีรูป</h3> : null
                }
                {
                    imgs && <Swiper
                        spaceBetween={10}
                        slidesPerView={1}
                    >
                        {
                            imgs && imgs.map((Img, index) => <SwiperSlide key={index}><a href={`${Img}`} target="_blank"><img style={{ width: '100%', objectFit: 'cover' }} src={`${Img}`} /></a></SwiperSlide>)
                        }
                    </Swiper>
                }
            </DialogContent>
            <DialogActions>
                {
                    data?.isAction ? <React.Fragment>
                        <Button onClick={handleClose} variant="contained" color="error">ยกเลิก</Button>
                        <Button onClick={handleSubmit} variant="contained" color='success'>
                            ตกลง
                        </Button>
                    </React.Fragment> :
                        <Button onClick={handleClose} variant="contained" color="error">ปิดหน้าต่าง</Button>
                }

            </DialogActions>
        </Dialog>
    )
}

export default DialogAccepCase