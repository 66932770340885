import { authConfig, providerGoogle, providerFacebook } from '../config/firebaseApi'
import { signOut, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth'
providerGoogle.setCustomParameters({ 'login_hint': 'user@example.com' })
providerGoogle.addScope('email');
providerFacebook.setCustomParameters({ 'display': 'popup' })
providerFacebook.addScope('user_birthday');
const LoginService = {}
LoginService.EmailAndPassword = async (email, password) => {
    return new Promise((resolve, reject) => {
        signInWithEmailAndPassword(authConfig, email, password).then((response) =>resolve(response)).catch(error => reject(error))
    })
}
LoginService.Google=()=>{
    return new Promise((resolve,reject)=>{
        signInWithPopup(authConfig, providerGoogle).then((result) =>resolve(result)).catch(error => reject(error))
    })
}
LoginService.Facebook=()=>{
    return new Promise((resolve,reject)=>{
        signInWithPopup(authConfig, providerFacebook).then((result) =>resolve(result)).catch(error => reject(error))
    })
}
export default LoginService;