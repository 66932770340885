import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import firebaseConfig from "../../config/firebaseApi";
import { collection, getDocs } from "firebase/firestore";
import Papa from "papaparse";

const { firestore } = firebaseConfig;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  margin: "auto", // Center the Paper
}));

const FormContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginTop: theme.spacing(5), // Add some top margin
}));

function FreeTrialAdmin() {
  const [freeTrialUsers, setFreeTrialUsers] = useState([]);

  useEffect(() => {
    const fetchFreeTrialUsers = async () => {
      const querySnapshot = await getDocs(
        collection(firestore, "freeTrialUsers")
      );
      const users = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setFreeTrialUsers(users);
    };

    fetchFreeTrialUsers();
  }, []);

  const handleExportCSV = () => {
    const csvData = freeTrialUsers.map((user) => ({
      Name: user.Name,
      Email: user.Email,
      PhoneNumber: user.PhoneNumber,
      LineID: user.LineID,
    }));
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "free_trial_users.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <FormContainer maxWidth="md">
      <Item>
        <Typography variant="h5">Free Trial Users For Admin</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Line ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {freeTrialUsers.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.Name}</TableCell>
                  <TableCell>{user.Email}</TableCell>
                  <TableCell>{user.PhoneNumber}</TableCell>
                  <TableCell>{user.LineID}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          variant="contained"
          color="primary"
          onClick={handleExportCSV}
          style={{ marginTop: "20px" }}
        >
          Export to CSV
        </Button>
      </Item>
    </FormContainer>
  );
}

export default FreeTrialAdmin;
