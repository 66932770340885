import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import LogHistoryService from '../../services/logHistory';
import moment from 'moment';
function LogHistory() {
  const [dataRows, setDataRows] = useState([])
  useEffect(() => {
    LogHistoryService.GetAll(JSON.parse(localStorage.getItem("uid")).company_id).then((response) => {
      response.forEach(e => {
        console.log('LogHistoryService', e.data())
        setDataRows(previous => [...previous, e.data()])
      });
    }).catch((error) => {
      console.log(error)
    })
  }, [])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <TableContainer component={Paper} style={{ maxHeight: '500px' }}>
      <Table sx={{ minWidth: 500 }} aria-label="customized table" stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">ผู้ใช้</StyledTableCell>
            <StyledTableCell align="center">ชื่อ</StyledTableCell>
            <StyledTableCell align="center">นามสกุล</StyledTableCell>
            <StyledTableCell align="center">วันที่เข้าใช้</StyledTableCell>
            <StyledTableCell align="center">เวลาเข้าใช้</StyledTableCell>
            <StyledTableCell align="center">รายละเอียด</StyledTableCell>
            <StyledTableCell align="center">โมเดล</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataRows && dataRows.map((row) => (
            <StyledTableRow key={row.email}>
              <StyledTableCell component="th" scope="row">
                {row.email}
              </StyledTableCell>
              <StyledTableCell align="center">{row.first_name}</StyledTableCell>
              <StyledTableCell align="center">{row.last_name}</StyledTableCell>
              <StyledTableCell width={150} align="center">{`${moment(row.time.seconds * 1000 + row.time.nanoseconds / 1000000).format("Do MMMM YYYY")}`}</StyledTableCell>
              <StyledTableCell align="center">{`${new Date(row.time.seconds*1000).getHours()}:${new Date(row.time.seconds*1000).getMinutes()}:${new Date(row.time.seconds*1000).getSeconds()}`  }</StyledTableCell>
              <StyledTableCell align="center">{row.message}</StyledTableCell>
              <StyledTableCell align="center">{row.name_model}</StyledTableCell>

            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default LogHistory