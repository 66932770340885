import React, { useState, useEffect, useContext } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import '../Css/NotifyPopup.css'
import Scurve from './overAllDialog/scurve';
import MapModel from './overAllDialog/mapModel';
import Docs from './overAllDialog/docs';
import ModelDetail from './modDetail/modelDetail';
import ChatRoom from './ChatRoom';

function DialogChat({ open, handleSave, handleClose }) {
    const [value, setValue] = useState('1');
    // const [data, setData] = useState(null)
    // const [label, setLabel] = useState("")
    // const [description, setDescription] = useState("")
    // const [createDate, setCreateDate] = useState(new Date());
    const handleClickClose = () => {
        if (typeof handleClose === 'function') {
            handleClose(false)
        }
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClickClose} >
            <Box sx={{ width: '100%', typography: 'body1' , height:'545px'}}>
                {/* <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" >
                            <Tab label="S-Curve" value="1" />
                        </TabList>
                    </Box>
                    <TabPanel value="1" style={{height:'600px'}}>
                        <ChatRoom/>
                    </TabPanel>
                   
                </TabContext> */}
                <ChatRoom/>
            </Box>
           
        </Dialog>
    )
}

export default DialogChat