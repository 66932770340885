import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { authConfig } from '../../config/firebaseApi';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Button, TextField } from '@mui/material';
import { useNavigate } from "react-router-dom";
import LoginService from '../../services/loginServices';
import ManagementUserService from '../../services/managementUserService';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { v4 as uuidv4 } from 'uuid';
import CompanyService from '../../services/companyService';
import { async } from '@firebase/util';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function SignupScreen() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [company, setCompany] = useState([])
    const [companySelect, setCompanySelect] = useState('')
    const [rePassword, setRePassword] = useState("")
    const [openDialog, setOpenDialog] = useState(false)
    const [getCompany, setGetCompany] = useState(null)
    useEffect(() => {
        onFetchCompany()
    }, [])

    const onFetchCompany = () => {
        CompanyService.GetAll().then((response) => {
            response.forEach(e => {
                setCompany(previous => [...previous, e.data()])
            });
        })
    }
    const handleSingUp = async () => {
        try {
            var id = uuidv4();
            if (companySelect === 'New Company') {
                // Handle new company creation
                var dataCompany = {
                    company_id: id,
                    name: companyName
                };
                CompanyService.Add(id, dataCompany).then((response) => {
                    console.log(response);
                    handleSave(id, companyName, 3); // Assume role 3 is for company admin
                }).catch((error) => {
                    console.log(error);
                });
            } else {
                var obj = company.find((i) => i.company_id === companySelect);
                const currentUsers = await ManagementUserService.GetAllUserInCompany(obj.company_id);
                if (currentUsers.docs.length >= 10) {
                    alert("บริษัทนี้มีผู้ใช้ครบ 5 บัญชีแล้ว ไม่สามารถเพิ่มผู้ใช้เพิ่มเติมได้");
                    return; // Stop the execution here to prevent further user creation
                }
    
                // Proceed to save the user if the condition is not met
                handleSave(obj.company_id, obj.name, 2); // Assume role 2 is for regular users
            }
        } catch (error) {
            alert(error);
        }
    };
    
    const handleSave = async (companyId, companyName, role) => {
        if (email && password && firstName && lastName) {
            if (password === rePassword) {
                createUserWithEmailAndPassword(authConfig, email, password).then((res) => {
                    console.log('User signed up with UID:', res.user.uid);
                    var createUser = {
                        first_name: firstName,
                        last_name: lastName,
                        email: email,
                        img: null,
                        uid: res.user.uid,
                        providerId: "email",
                        role: role,
                        company_id: companyId,
                        companyName: companyName
                    };
                    ManagementUserService.AddUser(res.user.uid, createUser).then((response) => {
                        navigate("/");
                    }).catch((error) => {
                        console.log(error);
                    });
                }).catch((error) => {
                    alert(error.message);
                });
            } else {
                alert("รหัสผ่านไม่ตรงกัน");
            }
        } else {
            alert("กรุณากรอกข้อมูลให้ครบทุกช่อง");
        }
    };
    
    return (
        <div className="Stage">
            <div className="Layer">

                <div className="AboveRegisterBox">
                    <img src="Icon TIS.png" className="Icon-Logo-tis" alt="TRIDENTLOGO" width="506" />
                    <img src="Icon BIM.png" className="Icon-Logo-bim" alt="BIMLOGO" width="320" />
                </div>

                <div className="RegisterBox">
                    <div className="RegisBox">
                        <p className="Text-01"> การลงทะเบียนเข้าใช้งาน BIMDatabase </p>
                        <p className="Text-02"> กรุณากรอกข้อมูลเพื่อเข้าสู่การใช้งานบัญชีของคุณ </p>

                        <div className="TextFieldZone">
                            <img src="Icon user.png" className="Icon-Logo" alt="Email" width="26" height="26" />
                            <input className="TextField" type="text" placeholder='ระบุชื่อจริง' onChange={(e) => setFirstName(e.target.value)} />
                        </div>


                        <div className="TextFieldZone">
                            <img src="Icon user.png" className="Icon-Logo" alt="Email" width="26" height="26" />
                            <input className="TextField" type="text" placeholder='ระบุนามสกุล' onChange={(e) => setLastName(e.target.value)} />
                        </div>

                        <div className="TextFieldZone">
                            <img src="Icon Company.png" className="Icon-Logo" alt="https://www.flaticon.com/free-icons/company" width="26" height="26" />
                            <select className='TextField' onChange={(e) => setCompanySelect(e.target.value)}>
                                <option selected={true} disabled="disabled" style={{ display: 'none' }}>โปรดเลือกบริษัท</option>
                                <option value={'New Company'}>หากไม่มีชื่อบริษัทคลิกที่ข้อความนี้</option>
                                {
                                    company.map((item, index) => <option key={index} value={item.company_id}>{item.name}</option>)
                                }
                            </select>
                            {/* <input className="TextField" type="text" placeholder='ระบุบริษัท' onChange={(e) => setCompany(e.target.value)} /> */}
                        </div>
                        {
                            companySelect === 'New Company' ? <div className="TextFieldZone">
                                <img src="Icon Company.png" className="Icon-Logo" alt="Email" width="26" height="26" />
                                <input className="TextField" type="text" placeholder='ระบุชื่อบริษัทใหม่' value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                            </div> : null
                        }
                        {/* <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">ตำแหน่ง</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={company}
                                label="ความสำคัญ"
                                onChange={(e) => setCompany(e.target.value)}
                            >
                                <MenuItem value={"ผู้ดู"}>ผู้ดู</MenuItem>
                                <MenuItem value={"ผู้จัดการ"}>ผู้จัดการ</MenuItem>
                                <MenuItem value={"เทียบเท่าเจ้าของ"}>เทียบเท่าเจ้าของ</MenuItem>
                            </Select>
                        </FormControl> */}


                        <div className="TextFieldZone">
                            <img src="Icon emails.png" className="Icon-Logo" alt="https://www.flaticon.com/free-icons/email" width="26" height="26" />
                            <input className="TextField" type="text" placeholder='ระบุอีเมล' onChange={(e) => setEmail(e.target.value)} />
                        </div>


                        <div className="Clear" />

                        <div className="TextFieldZone">
                            <img src="Icon passwords.png" className="Icon-Logo" alt="Password" width="26" height="26" />
                            <input className="TextField" type="password" placeholder='ระบุรหัสผ่าน' onChange={(e) => setPassword(e.target.value)} />
                        </div>


                        <div className="TextFieldZone">
                            <img src="Icon passwords.png" className="Icon-Logo" alt="Password" width="26" height="26" />
                            <input className="TextField" type="password" placeholder='ระบุรหัสผ่านอีกครั้ง' onChange={(e) => setRePassword(e.target.value)} />
                        </div>
                        <div className="Clear" />
                    </div>
                </div>

                <div className="UnderLoginBox">
                    <button className="LoginButton-Button" onClick={handleSingUp}>
                        <p className="LoginButton-Text"> ลงทะเบียน </p>
                    </button>
                    {/* <button className="LoginButton-Button" onClick={()=>console.log(companySelect)}>
                        <p className="LoginButton-Text"> เทส </p>
                    </button> */}
                    <div className="Clear" />
                </div>

                <div className="Credit">
                    <hr className="FootageLine">
                    </hr>
                    <p className="FootageMessage"> Copyright  © 2021,  Trident Intelligence Service </p>
                </div>

            </div>

        </div>
    )
}

export default SignupScreen