import React, { useState, useEffect, useContext, createContext, useRef } from 'react'
import '../../Css/Model.css'
import ViewerScreen from '../viewerScreen'
import Menubar from '../../components/Menubar'
import DbModelsService from '../../services/dbModelsService'

import { AuthContext } from '../../auth/auth'
import { useNavigate } from "react-router-dom";
import NameModel from '../../components/NameofModel'
import PushPinServices from '../../services/pushPinServices'
import ModelViewer from '../../components/modelViewer'

const ManinScreen = () => {
  console.log("local", JSON.parse(localStorage.getItem("uid")).role)
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [urn, setUrn] = useState("")
  const [name, setName] = useState("โมเดลเริ่มต้น")
  useEffect(() => {
    if (!currentUser) {
      navigate("/")
    } else {
      console.log("currentUser", currentUser.uid)
    }
    return () => {
      console.log("clear")
    }
  }, [currentUser])
  var PushPinExtensionHandle;
  const handlePushPin = (obj) => {
    console.log("PushPinExtensionHandle-main", PushPinExtensionHandle)
    PushPinExtensionHandle = obj
    // handlefetchPushPin()
  }
  const handleCreatePushPin = (markup) => {
    console.log("handleCreatePushPin", markup)
    PushPinExtensionHandle.startCreateItem({
      id: markup.id,
      label: markup.label,
      description: markup.description,
      status: markup.status,
      type: markup.type,
      isCreate: markup.isCreate,
      isAction: markup.isAction,
      datecreate: markup.datecreate,
      priority: markup.priority,
      imgs: markup.imgs,
      model_ref: markup.model_ref
    })

  }
  // LogHistoryService.Add(JSON.parse(localStorage.getItem("uid")).company_id)
  const onSaveUploadModel = (obj) => {
    DbModelsService.AddModel(obj.project_name, obj.name_model, obj).then((response) => {
      console.log("Sucess")
    }).catch((error) => {
      console.log(error)
    })
  }
  const onSaveToken = (token) => {
    setUrn(token)

  }
  const handleSubmit = (row, data) => {
    console.log('handleAccep', data.id)
    PushPinServices.Update(row, data.id).then((response) => {
      console.log('res', response)
    }).catch((error) => {
      console.log(error)
    })
  }
  return (
    <div className='Dashboard'>
      <Menubar
        handleOnSave={handleCreatePushPin}
        handleSaveUploadModel={onSaveUploadModel}
        handleSaveToken={onSaveToken}
        nameModel={setName}
        onSubmit={handleSubmit}
      />
      <ViewerScreen token={urn} handleResPushPin={handlePushPin} />
      <NameModel nameModel={name} />
    </div>
  )
}

export default ManinScreen