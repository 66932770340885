import React, { useState, useEffect } from 'react'
import ModelViewer from '../../components/modelViewer'
import ModelDetailServices from '../../services/modelDetailServices'
function ViewerScreen({ token, handleResPushPin, modelDetail }) {
  var data = null
  console.log("token", token)
  var PushPinExtensionHandle;
  const handlePushPin = async (obj) => {
    PushPinExtensionHandle = await obj
    if (typeof handleResPushPin === "function") {
      if (PushPinExtensionHandle) {
        handleResPushPin(PushPinExtensionHandle)
      } else {
        console.log("else", PushPinExtensionHandle)
      }
    }
  }
  const handleModelDetail = (res) => {
    localStorage.setItem("dataModel", JSON.stringify({ data: res }))
    console.log("handleModelDetail", JSON.parse(localStorage.getItem("dataModel")).data.dbId)
    var data = []
    ModelDetailServices.Get(JSON.parse(localStorage.getItem("dataModel")).data.dbId).then((res) => {
      res.forEach(e => {
        console.log("ModelDetailServices", e.data())
        data.push({
          id: e.data().id,
          field: e.data().displayName,
          value: e.data().displayValue,
          catagory: e.data().displayCategory
        })
      });
      // console.log("ModelDetailServices", res.data())
      localStorage.setItem("detailItem", JSON.stringify({ data: data }))
    }).catch((error) => {
      console.log("ModelDetailServices", error)
    })

  }
  return (
    <div>
      <ModelViewer
        urn={token}
        handlePushPin={handlePushPin}
        modelDetail={handleModelDetail}
      />
    </div>

  )
}

export default ViewerScreen