import { useState, useEffect, useRef } from "react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import '../Css/ChatRoom.css';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Avatar,
  ConversationHeader,
  MessageSeparator
} from "@chatscope/chat-ui-kit-react";
import ChatServices from "../services/ChatServics";
import moment from 'moment';
import ManagementUserService from '../services/managementUserService';
import firebase from '../config/firebaseApi';
import {
  collection,
  onSnapshot,
  query,
  orderBy
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import 'moment/locale/th'; // without this line it didn't work
moment.locale('th');

const ChatRoom = () => {

  const fileInputRef = useRef(null);

  const [msg, setMsg] = useState("");
  const [dataChat, setDataChat] = useState([]);
  const [dataImg, setDataImg] = useState('');
  const [dataTime, setDataTime] = useState([]);
  const [uid, setUid] = useState(JSON.parse(localStorage.getItem("uid")).uid);
  const [userImg, setUserImg] = useState('https://www.freeiconspng.com/uploads/customers-icon-3.png'); // รูปโปรไฟล์ของผู้ใช้

  const handleUploadImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const storage = getStorage();
      const storageRef = ref(storage, 'chatImages/' + file.name);
      uploadBytes(storageRef, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          setDataImg(downloadURL);
          sendMessage(downloadURL); // ส่งข้อความเมื่อได้ URL ของรูปภาพแล้ว
        });
      });
    }
  };

  const sendMessage = (imgUrl) => {
    var data = {
      message: msg,
      sentTime: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
      sender: JSON.parse(localStorage.getItem("uid")).uid,
      position: "single",
      img: imgUrl
    };
    ChatServices.Send(data).then((resSend) => {
      setMsg('');
      setDataImg(''); // รีเซ็ตรูปภาพหลังจากส่งข้อความ
    }).catch((error) => {
      console.error("Error sending message: ", error);
    });
  };

  useEffect(() => {
    onfetchChat();
    onfetchUserInfo();
  }, []);

  const onfetchUserInfo = () => {
    ManagementUserService.GetUserInfoOnce(uid).then((response) => {
      if (response.exists()) {
        setUserImg(response.data().img || 'https://www.freeiconspng.com/uploads/customers-icon-3.png'); // ใช้รูปโปรไฟล์จากฐานข้อมูลหรือรูปเริ่มต้น
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  const onfetchChat = () => {
    const q = query(collection(firebase.firestore, "chats", "Models", JSON.parse(localStorage.getItem("model_detail")).model_ref), orderBy("sentTime", "asc"));
    const unsub = onSnapshot(q, (docs) => {
      setDataChat([]);
      const time = [];
      docs.forEach(doc => {
        if (!time.includes(doc.data().sentTime.substring(0, 10))) {
          time.push(doc.data().sentTime.substring(0, 10));
        }

        setDataChat((previous) => [...previous, {
          message: doc.data().message,
          sentTime: doc.data().sentTime,
          sender: doc.data().sender,
          direction: (doc.data().sender === JSON.parse(localStorage.getItem("uid")).uid ? "outgoing" : "incoming"),
          img: doc.data().img,
          name: doc.data().name
        }]);
      });
      setDataTime(time);
    });
  };

  return (
    <div style={{ position: "relative", height: "500px" }}>
      <input
        ref={fileInputRef}
        accept="image/*"
        style={{ display: "none" }}
        type="file"
        onChange={handleUploadImage}
      />
      <ConversationHeader>
        <ConversationHeader.Content userName={'ชื่อโมเดล: ' + JSON.parse(localStorage.getItem("model_detail")).name_model} />
      </ConversationHeader>
      <MainContainer>
        <ChatContainer>
          <MessageList>
            {
              dataTime.map((time, i) => {
                let msg = dataChat.map((val, index) => {
                  if (val.sentTime.substring(0, 10) === time) {
                    return (
                      <Message
                        key={index}
                        model={{
                          message: val.message,
                          sentTime: val.sentTime,
                          sender: val.sender,
                          direction: val.direction,
                          position: "single"
                        }}
                      >
                        <Message.Header sender={val.name} sentTime={val.sentTime.split('T')[1].substring(0, 5)} />
                        <Avatar src={userImg} name={val.name} referrerpolicy="no-referrer" /> {/* ใช้ userImg แทน */}
                        {val.img && (
                          <Message.CustomContent>
                            <img src={val.img} alt="chat-img" style={{ maxWidth: '100%' }} />
                          </Message.CustomContent>
                        )}
                      </Message>
                    );
                  }
                });

                return [
                  <MessageSeparator key={i} content={moment().subtract(moment().diff(moment(time, 'YYYY/MM/DD'), 'days'), 'days').calendar().split("เวลา")[0]} />,
                  msg
                ];
              })
            }
          </MessageList>
          <MessageInput
            placeholder="พิมพ์ข้อความที่นี่ . . ."
            value={msg}
            onChange={(val) => setMsg(val)}
            onAttachClick={() => fileInputRef.current.click()}
            onSend={() => sendMessage(dataImg)}
          />
        </ChatContainer>
      </MainContainer>
    </div>
  )
}

export default ChatRoom;
