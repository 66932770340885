import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
// import { TextField , Grid } from '@material-ui/core';

import DialogTitle from '@mui/material/DialogTitle';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
const DialogImg = ({ open, onClose, item }) => {

    const handleClose = () => {
        if (typeof onClose === 'function') {
            onClose(false)
        }
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">
                {"รายการภาพ"}
            </DialogTitle>
            <DialogContent >
            {/* <Grid item xs={12}>
               
            </Grid> */}
                {
                    item && item.length === 0 ? <h3 style={{ textAlign: 'center' }}>ไม่มีรูป</h3> : null
                }
                {item && <Swiper
                    spaceBetween={10}
                    slidesPerView={1}
                >
                    {
                        item && item.map((Img, index) => <SwiperSlide key={index}><a href={`${Img}`} target="_blank"><img style={{ width: '100%', objectFit: 'cover' }} src={`${Img}`} /></a></SwiperSlide>)
                    }
                </Swiper>}
            </DialogContent>
           
        </Dialog>
    )
}

export default DialogImg