import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import QueryModelsService from '../services/queryModelsService';

const SelectModel = ({ handleChangeToken, nameModel }) => {
    const [tokenModels, setTokenModels] = useState([]);
    const [projectName, setProjectName] = useState([]);

    useEffect(() => {
        fetchModels();
    }, []);

    const fetchModels = async () => {
        const data = [];
        const dataProject = [];
        QueryModelsService.GetModelSelect().then((response) => {
            response.forEach((doc) => {
                data.push({ name_model: doc.data().name_model, token: doc.data().token_model, model_ref: doc.data().model_ref, projectName: doc.data().project_name });
                if (!dataProject.includes(doc.data().project_name)) {
                    dataProject.push(doc.data().project_name);
                }
            });
            setTokenModels(data);
            setProjectName(dataProject);
        });
    };

    const handleChangeModel = (option) => {
        if (typeof nameModel === "function") {
            nameModel(option.name_model);
            handleChangeToken(option.token);
            localStorage.setItem("token", option.token);
            localStorage.setItem("model_detail", JSON.stringify({ name_model: option.name_model, token: option.token, model_ref: option.model_ref }));
        }
    };

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 'auto', // Adjust width to auto for responsiveness
            },
        },
    };

    return (
        <FormControl sx={{
            m: 1, minWidth: 300,
            '& label.Mui-focused': {
                color: '#FF8B00',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: '#FF8B00',
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderRadius: '2rem',
                },
                '&:hover fieldset': {
                    borderColor: '#FF8B00',
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#FF8B00',
                },
                borderRadius: '2rem',
                backgroundColor: 'white',
            },
        }}>
            <InputLabel htmlFor="grouped-select" style={{ color: '#BCBFC0', paddingBottom: "1rem" }}>ค้นหาโครงการที่ต้องการเปิด</InputLabel>
            <Select
                id="grouped-select"
                MenuProps={MenuProps}
                style={{ backgroundColor: 'white', borderRadius: '2rem' }}
            >
                {projectName.map((item, index) => {
                    let modelCount = 0; // Initialize counter for each project
                    return [
                        <ListSubheader key={index} component="div" style={{ fontSize: '1rem' }}>
                            {"ชื่อโครงการ: " + item}
                        </ListSubheader>,
                        tokenModels.map((item2) => {
                            if (item2.projectName === item) {
                                modelCount += 1; // Increment model count
                                return <MenuItem key={item2.token} onClick={() => handleChangeModel(item2)}>
                                    <Typography variant="body2">
                                        {"model " + modelCount + ": " + item2.name_model}
                                    </Typography>
                                </MenuItem>;
                            }
                            return null;
                        })
                    ];
                })}
            </Select>
        </FormControl>
    );
}

export default SelectModel;
