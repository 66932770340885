import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import FreeTrialApp from './FreeTrialApp';

const RootComponent = () => {
  // Use more complex logic if needed
  const isFreeTrial = window.location.pathname.startsWith('/free-trial-signup');

  return isFreeTrial ? <FreeTrialApp /> : <App />;
}

ReactDOM.render(
  <React.StrictMode>
    <RootComponent />
  </React.StrictMode>,
  document.getElementById('root')
);
