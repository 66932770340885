import { useState, useEffect } from 'react'
import Dropdown from './ApproveSelect'
import ManagementUserService from '../../services/managementUserService';
import LogHistoryService from '../../services/logHistory';
import UserAccount from '../../images/UserAccount.png';
function Approve() {
    const [person, setPerson] = useState([]);
    useEffect(() => {
        onFetchUsers()
    }, [])

    const onFetchUsers = () => {

        ManagementUserService.GetAllUserInCompany(JSON.parse(localStorage.getItem("uid")).company_id).then((response) => {
            setPerson([])
            response.forEach(e => {
                console.log('dd', e.data())
                setPerson(previous => [...previous, e.data()])
            });

        }).catch((error) => {

        })
    }
    const handleOnSave = (obj, user) => {
        console.log('handleOnSave', obj, user)
        var data = {
            role: obj.value
        }
        ManagementUserService.UpdateRole(user.uid, data).then((response) => {
            var data = {
                uid: JSON.parse(localStorage.getItem("uid")).uid,
                email: JSON.parse(localStorage.getItem("uid")).email,
                first_name: JSON.parse(localStorage.getItem("uid")).first_name,
                last_name: JSON.parse(localStorage.getItem("uid")).last_name,
                time: new Date(),
                message: "อัพเดตสิทธิการเข้าถึง",
                model_ref: JSON.parse(localStorage.getItem("model_detail")).model_ref,
                name_model: JSON.parse(localStorage.getItem("model_detail")).name_model
            }
            LogHistoryService.Add(JSON.parse(localStorage.getItem("uid")).company_id, data).then((response) => {
                response(response)

            }).catch((error) => {
                console.log(error)
            })
            setPerson(previous => previous.map((p) => {
                if (p.uid === user.uid) {
                    return { ...p, role: obj.value }
                } else {
                    return p
                }
            }))

        }).catch((error) => {
            console.log(error)
        })
    }



    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <h3 style={{ fontWeight: 'bold', color: '#595857', marginBottom: '1rem' }}>ผู้ใช้งานในระบบ</h3>
            <div className="Box-App" style={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: '1rem', justifyContent: 'flex-start', alignItems: 'center', padding: '0 5.5rem' }}>
                {person.map((item, index) => {
                    return (
                        <div className='cardApp' key={index}>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <div>
                                    <img src={item?.img ? item?.img:UserAccount} width='40px' height='40px' style={{ objectFit: 'cover', borderRadius: '100%' }} />
                                </div>
                                <div style={{ marginLeft: '1rem' }}>
                                    <p style={{ margin: '0', fontWeight: 'bold' }}>{item.first_name} {item.last_name}</p>
                                    <p style={{ margin: '0' }}>{item.email}</p>
                                </div>
                            </div>
                            <div style={{ marginTop: '0.5rem' }}>
                                <p>ชื่อบริษัท</p>
                                <p style={{ margin: '0', fontWeight: 'bold', color: 'rgb(159, 87, 0)' }}>{item.companyName}</p>
                            </div>
                            <div style={{ marginTop: '0.5rem' }}>
                                <p>สถานะปัจจุบัน</p>
                                <p style={{ margin: '0', fontWeight: 'bold', color: 'rgb(159, 87, 0)' }}>{item.role == 1 ? 'ผู้ดู' : item.role == 2 ? 'ผู้จัดการ' : item.role == 3 ? 'เทียบเท่าเจ้าของ' : null}</p>
                            </div>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', marginTop: '1rem' }}>
                                {JSON.parse(localStorage.getItem("uid")).role > item.role ? <Dropdown onSave={handleOnSave} data={item} /> : null}
                            </div>
                        </div>
                    )
                })}

            </div>

        </div>
    )
}

export default Approve