import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CompanyService from '../../services/companyService';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid'
import ManagementUserService from '../../services/managementUserService';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { set } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
const DialogAddCompany = ({ open, onClose }) => {
    const [company, setCompany] = useState([])
    const [selectCompany, setSelectCompany] = useState(null)
    const [role, setRole] = useState(0)
    const [isShow, setIsShow] = useState(false)
    const [text, setText] = useState('')
    useEffect(() => {
        onFetchCompany()
    }, [])

    const onFetchCompany = () => {
        setCompany([])
        CompanyService.GetAll().then((response) => {
            response.forEach(e => {
                setCompany(previous => [...previous, e.data()])
            });
        })
    }
    const handleClose = () => {
        if (typeof onClose === 'function') {
            onClose(false)
        }
    };

    const handleOnSelect = (e) => {
        console.log('e', e.target.value)
        setSelectCompany(e.target.value)
        setRole(2)
    }
    const handleChange = (e) => {
        setText(e.target.value)
        var data = {
            company_id: uuidv4(),
            name: text
        }
        setSelectCompany(data)
        setRole(3)
    }
    const handleOnSave = () => {

        var data = {
            role: role,
            company_id: selectCompany.company_id,
            companyName: selectCompany.name
        }
        console.log(data)
        if (role === 3) {
            CompanyService.Add(selectCompany.company_id, { company_id: selectCompany.company_id, name: selectCompany.name }).then((response) => {
                console.log(response)
            }).catch((error) => {
                console.log(error)
            })
        }
        ManagementUserService.UpdateCompany(JSON.parse(localStorage.getItem("uid")).uid, data).then((response) => {
            console.log('res', response)
            onClose()
        }).catch((error) => {
            console.log(error)
        })
    }

    const handleIsShow = () => {

        setIsShow(!isShow)
    }
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth maxWidth="sm"
        >

            <DialogTitle id="alert-dialog-title">
                {"เลือกบริษัทของคุณ"}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} >
                    <Grid item xs={12} style={{ display: isShow ? 'none' : 'block' }}>
                        <FormControl fullWidth sx={{
                            '& label.Mui-focused': {
                                color: '#FF8B00',
                            },
                            '& .MuiInput-underline:after': {
                                borderBottomColor: '#FF8B00',
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderRadius: '2rem',

                                },
                                '&:hover fieldset': {
                                    borderColor: '#FF8B00',

                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#FF8B00',
                                },
                                borderRadius: '2rem',
                                backgroundColor: 'white',
                            },
                        }}>
                            <Select
                                fullWidth
                                disabled={isShow}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectCompany}
                                label="company"
                                onChange={handleOnSelect}

                            >
                                {company && company.map((v, i) => (
                                    <MenuItem value={v} key={`selec-company-item-${i}`}>{v.name}</MenuItem>
                                ))}

                            </Select>

                        </FormControl>

                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={handleIsShow} sx={{
                            backgroundColor: '#fff',
                            color: '#7C7B7B',
                            borderRadius: '2rem',
                            fontWeight: 'bolder',
                            fontSize: '16px',
                            '&:hover': {
                                color: '#FF8B00',
                                backgroundColor: '#fff',
                            }

                        }} >{isShow ? 'เลือกบริษัทที่มี' : 'เพิ่มบริษัท'}</Button>
                    </Grid>
                    {
                        isShow && <Grid item xs={12}>
                            <TextField label="ชื่อบริษัท" onChange={handleChange} sx={{
                                '& label.Mui-focused': {
                                    color: '#FF8B00',
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: '#FF8B00',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderRadius: '2rem',

                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#FF8B00',

                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#FF8B00',
                                    },
                                    borderRadius: '2rem',
                                    backgroundColor: 'white',
                                },
                            }} />
                        </Grid>
                    }

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} sx={{
                    backgroundColor: '#fff',
                    color: '#7C7B7B',
                    borderRadius: '2rem',
                    fontWeight: 'bolder',
                    fontSize: '16px',
                    '&:hover': {
                        color: '#FF8B00',
                        backgroundColor: '#fff',
                    }

                }} >ยกเลิก</Button>
                <Button onClick={handleOnSave} autoFocus sx={{
                    backgroundColor: '#fff',
                    color: '#7C7B7B',
                    borderRadius: '2rem',
                    fontWeight: 'bolder',
                    fontSize: '16px',
                    '&:hover': {
                        color: '#FF8B00',
                        backgroundColor: '#fff',
                    }

                }} >
                    ยืนยัน
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DialogAddCompany