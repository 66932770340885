import React, { useState } from 'react'
import Swal from 'sweetalert2'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
function ApproveSelect({ onSave, data }) {
    const [permission, setPermission] = useState('');
    const [items, setItems] = useState([{
        value: 1,
        name: 'ผู้ดู'
    },
    {
        value: 2,
        name: 'ผู้จัดการ'
    },
    {
        value: 3,
        name: 'ประธาน'
    }
    ])


    const Popup = () => {

        return Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'เปลี่ยนตำแหน่งสำเร็จ',
            showConfirmButton: true,
            confirmButtonColor: '#F7941D',
            confirmButtonText: 'รับทราบ',
            timer: 4200
        })
        // setOnOpenReset(true)
    }

    const handleAccep = () => {
        if (typeof onSave === 'function') {
            onSave(permission, data)
            Popup()
        }
    }
    return (
        <React.Fragment>
            <div style={{ width: '100%' }}>
                <FormControl fullWidth sx={{
                    '& label.Mui-focused': {
                        color: '#FF8B00',
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: '#FF8B00',
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderRadius: '2rem',

                        },
                        '&:hover fieldset': {
                            borderColor: '#FF8B00',

                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#FF8B00',
                        },
                        borderRadius: '2rem',
                        backgroundColor: 'white',
                    },
                }}>
                    <InputLabel id="demo-simple-select-label">ตำแหน่ง</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={permission}
                        label="ความสำคัญ"
                        onChange={(e) => setPermission(e.target.value)}
                    >
                        {items && items.map((e, i) => (
                             JSON.parse(localStorage.getItem("uid")).role >= e.value ? <MenuItem value={e} key={i}>{e.name}</MenuItem> : null 
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '0.5rem', alignItems: 'center' }}>
                {/* <button className="btn-rej" onClick={() => console.log("Reject", permission)}>
                    ✕
                </button> */}
                <button className="btn-app" onClick={() => handleAccep()}>
                    ✓
                </button>
            </div>
        </React.Fragment>

    )
}

export default ApproveSelect