import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import LogHistory from './LogHistory';
import Approve from '../userManagement/Approve';
import UsersBim from '../UsersBim';
import '../../Css/NotifyPopup.css'
function DialogPermission({ open, handleSave, handleClose }) {
    const [value, setValue] = useState('2');
    const [data, setData] = useState(null)
    const [label, setLabel] = useState("")
    const [description, setDescription] = useState("")
    const [createDate, setCreateDate] = useState(new Date());
    const handleClickClose = () => {
        if (typeof handleClose === 'function') {
            handleClose(false)
        }
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    // const handleChangeDate = (newValue) => {
    //     setCreateDate(newValue);
    // };
    // const handleOnSave=(obj)=>{
    //     if(typeof handleSave==="function"){
    //         handleSave(obj)
    //     }

    // }
    return (
        <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClickClose} >
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" sx={{
                            '& .MuiTabs-indicator': {
                                backgroundColor: '#FF8B00',
                            },
                            '& .MuiTabs-flexContainer ': {

                                '& .Mui-selected': {
                                    color: '#FF8B00',

                                },
                            }
                        }}>
                            {/* <Tab label="พูดคุย" value="1" /> */}
                            {JSON.parse(localStorage.getItem("uid")).role === 3 ? <Tab label="ประวัติ" value="1" /> : null}
                            {JSON.parse(localStorage.getItem("uid")).role !==1  ? <Tab label="ผู้ใช้งาน" value="2" /> : null}

                            {/* <Tab label="อนุมัติแล้ว" value="3" /> */}
                        </TabList>
                    </Box>

                    <TabPanel value="1">
                        {JSON.parse(localStorage.getItem("uid")).role === 3 ? <LogHistory /> : null}

                    </TabPanel>
                    <TabPanel value="2" >
                        {JSON.parse(localStorage.getItem("uid")).role !== 1 ? <Approve /> : null}
                    </TabPanel>
                </TabContext>
            </Box>
        </Dialog>
    )
}

export default DialogPermission