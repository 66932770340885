import { authConfig, providerGoogle, providerFacebook } from '../config/firebaseApi'
import { GoogleAuthProvider, FacebookAuthProvider, sendPasswordResetEmail } from 'firebase/auth'
providerGoogle.setCustomParameters({ 'login_hint': 'user@example.com' })
providerFacebook.setCustomParameters({ 'display': 'popup' })

const ResetPasswordService = {}

ResetPasswordService.RetPassword = (email) => {
    return new Promise((resolve, reject) => {
        sendPasswordResetEmail(authConfig, email).then((response) => resolve(response)).catch((error) => reject(error))
    })
}

export default ResetPasswordService;