import React, { useState, useEffect, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import UploadImg from '../notifyfixDialog/UploadImg';
import moment from 'moment'
import InputBase from '@mui/material/InputBase';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import '../../Css/NotifyPopup.css'
import PushPinServices from '../../services/pushPinServices';
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
    //   marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: '2rem',
      borderColor: '#FF8B00',
      position: 'relative',
      backgroundColor: '#fff',
      padding: '10px 26px 10px 12px',
      '&:focus': {
        borderRadius: '2rem',
        borderColor: '#FF8B00',
      },
    },
  }));

const Input = styled('input')({
    display: 'none',
});
const PushPin = ({ handleSave }) => {
    const [value, setValue] = useState('1');
    const [data, setData] = useState(null)
    const [label, setLabel] = useState("")
    const [description, setDescription] = useState("")
    const [createDate, setCreateDate] = useState(new Date());
    const [priority, setPriority] = useState("")
    const [FileUpload, setFileUpload] = useState([])
    const [formData, setFormData] = useState(null)
    const [imgs, setImgs] = useState([])
    const [urlImg, setUrlImg] = useState([])
    const imgsUrl = []
    const handleChangeDate = (newValue) => {
        setCreateDate(newValue);
    };
  
    const deleteSelectImg = (img) => {
        setImgs((previous) => [...previous.filter(p => p.id !== img.id)]);
    }
    const pushData = async () => {
        var uuid = uuidv4()
        imgs.forEach(e => {
            onFileChange(e, uuid)
        });

        if (imgs.length === 0) {

            var dataPinpush = {
                id: uuid,
                label: label,
                description: description,
                status: 'open',
                type: 'issues',
                isCreate: true,
                isAction: true,
                datecreate: createDate,
                priority: priority,
                imgs: [],
                model_ref:JSON.parse(localStorage.getItem('model_detail')).model_ref
            }
            if (typeof handleSave === 'function') {
                handleSave(dataPinpush)
            }

        }


     
    }
    const onFileChange = async (e, uuid) => {
        const url = []
        const file = e.obj
        const storage = getStorage();
        const metadata = {
            contentType: 'image/jpeg'
        };
        const filename = `${moment(new Date()).format("YYYY-MM-DDTHH:mm:ss") + "name-" + file.name}`

        const storageRef = ref(storage, `images/pushpin/${uuid}/` + filename);
        const uploadTask = uploadBytesResumable(storageRef, file, metadata);
        uploadTask.on('state_changed',
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                switch (error.code) {
                    case 'storage/unauthorized':
                        break;
                    case 'storage/canceled':
                        break;
                    case 'storage/unknown':
                        break;
                }
            },
            () => {

                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    // setUrlImg(previous=>[...previous,{uuid:uuid,img:downloadURL}])
                    imgsUrl.push(downloadURL)
                    console.log('File available at', downloadURL);
                    // handleOnSaveImg(uuid, downloadURL)
                });
                console.log(imgsUrl)
                var dataPinpush = {
                    id: uuid,
                    label: label,
                    description: description,
                    status: 'open',
                    type: 'issues',
                    isCreate: true,
                    isAction: true,
                    datecreate: createDate,
                    priority: priority,
                    imgs: imgsUrl,
                    model_ref:JSON.parse(localStorage.getItem('model_detail')).model_ref
                }
                if (typeof handleSave === 'function') {
                    handleSave(dataPinpush)
                }


            },
        );


    };

    // var count = 0
    // const handleOnSaveImg = (uuid, e) => {
    //     var data = {
    //         count: 555,
    //         img: e
    //     }
    //     i.push(data)
    // }

    // useEffect(() => {
    //     console.log('ddddddddddddddddddd',urlImg)
    // }, [urlImg])

    return (

        <Grid container spacing={2}>
            <Grid item xs={12}>
                <InputLabel style={{ marginBottom: '1rem', color: '#6F6C6C', fontWeight: 'bolder', marginLeft: '0.5rem' }}>หัวข้อรายการแจ้ง</InputLabel>
                <TextField id="outlined-basic"  sx={{
                    '& label.Mui-focused': {
                        color: '#FF8B00',
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: '#FF8B00',
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderRadius: '2rem',
                            
                        },
                        '&:hover fieldset': {
                            borderColor: '#FF8B00',
                            
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#FF8B00',
                        },
                        borderRadius: '2rem',
                        backgroundColor: 'white',
                    },
                }}fullWidth label="รายการแจ้ง" variant="outlined" color='primary' onChange={(e) => setLabel(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
                <InputLabel style={{ marginBottom: '1rem', color: '#6F6C6C', fontWeight: 'bolder', marginLeft: '0.5rem' }}>รายละเอียดการแจ้ง</InputLabel>
                <TextField id="outlined-basic"  sx={{
                    '& label.Mui-focused': {
                        color: '#FF8B00',
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: '#FF8B00',
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderRadius: '2rem',
                            
                        },
                        '&:hover fieldset': {
                            borderColor: '#FF8B00',
                            
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#FF8B00',
                        },
                        borderRadius: '2rem',
                        backgroundColor: 'white',
                    },
                }} fullWidth label="รายละเอียด" variant="outlined" onChange={(e) => setDescription(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
                <InputLabel style={{ marginBottom: '1rem', color: '#6F6C6C', fontWeight: 'bolder', marginLeft: '0.5rem' }}>วันที่แจ้งซ่อม</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        label="วันที่แจ้งซ่อม"
            
                       
                        value={createDate}
                        onChange={handleChangeDate}
                        renderInput={(params) => <TextField fullWidth {...params}    sx={{
                            '& label.Mui-focused': {
                                color: '#FF8B00',
                            },
                            '& .MuiInput-underline:after': {
                                borderBottomColor: '#FF8B00',
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderRadius: '2rem',
                                    
                                },
                                '&:hover fieldset': {
                                    borderColor: '#FF8B00',
                                    
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#FF8B00',
                                },
                                borderRadius: '2rem',
                                backgroundColor: 'white',
                            },
                        }}/>}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
                <InputLabel style={{ marginBottom: '1rem', color: '#6F6C6C', fontWeight: 'bolder', marginLeft: '0.5rem' }}>ระดับความสำคัญ</InputLabel>
                <FormControl fullWidth  sx={{
                                '& label.Mui-focused': {
                                    color: '#FF8B00',
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: '#FF8B00',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderRadius: '2rem',
                                        
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#FF8B00',
                                        
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#FF8B00',
                                    },
                                    borderRadius: '2rem',
                                    backgroundColor: 'white',
                                },
                            }}>
                    <InputLabel id="demo-simple-select-label">ความสำคัญ</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={priority}
                        label="ความสำคัญ"
                    
                       
                        onChange={(e) => setPriority(e.target.value)}
                    >
                        <MenuItem value={"ไม่รีบ"}>ไม่ด่วน</MenuItem>
                        <MenuItem value={"ด่วน"}>ด่วน</MenuItem>
                        <MenuItem value={"ด่วนมาก"}>ด่วนมาก</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <UploadImg setImgs={setImgs} pushData={pushData} />
            {/* <Button variant="contained" className='px-4' onClick={pushData}>บันทึกข้อมูล</Button> */}
            {imgs.map((item, key) => (
                <Grid item xs={12} sm={6} md={3} key={`showItem-` + key}>
                    <Card>
                        <CardMedia
                            component="img"
                            height="194"
                            image={item.img}
                            sx={{ objectFit: "cover" }}
                        />
                        <CardActions>
                            <Button
                                variant="text"
                                color="error"
                                startIcon={<HighlightOffIcon />}
                                size="small"
                                onClick={() => deleteSelectImg(item)}
                            >
                                Delete
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>



    )
}

export default PushPin