import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { Dialog } from '@material-ui/core';
import { Grid } from '@mui/material'
import { Button } from '@mui/material';
import UserAccount from '../images/UserAccount.png';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from "@mui/material/styles";
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment'
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import ManagementUserService from '../services/managementUserService';
const Input = styled("input")({
    display: "none",
});
const commonStyles = {
    bgcolor: 'background.paper',
    borderColor: 'text.primary',
    m: 1,
    border: 1,
    width: '5rem',
    height: '5rem',
};

const UserInfo = ({ openUserInfo, dataInfo, onClose ,onUpdate}) => {
    const [imgUrl, setImgUrl] = useState("")
    const handleClickClose = () => {
        if (typeof onClose === "function") {
            onClose(false)
        }
    }
    useEffect(() => {
        setImgUrl(dataInfo)
    }, [])
    
    const handleUploadImg = (e) => {
        const url = []
        const file = e
        const storage = getStorage();
        const metadata = {
            contentType: 'image/jpeg'
        };
        const filename = `${moment(new Date()).format("YYYY-MM-DDTHH:mm:ss") + "name-" + file.name}`

        const storageRef = ref(storage, `images/profile/${JSON.parse(localStorage.getItem("uid")).uid}/` + filename);
        const uploadTask = uploadBytesResumable(storageRef, file, metadata);
        uploadTask.on('state_changed',
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                switch (error.code) {
                    case 'storage/unauthorized':
                        break;
                    case 'storage/canceled':
                        break;
                    case 'storage/unknown':
                        break;
                }
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    setImgUrl(downloadURL)
                    var data={
                        img:downloadURL
                    }
                    ManagementUserService.UpdateImg(JSON.parse(localStorage.getItem("uid")).uid,data).then((response)=>{
                        console.log('response',response)
                        if(typeof onUpdate==='function'){
                            onUpdate()
                        }
                    }).catch((error)=>{
                        console.log('response',error)
                    })
                });
            },
        );

    }
    return (
        <Dialog fullWidth maxWidth="sm" open={openUserInfo} onClose={handleClickClose} >

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'space-between', width: '100%', padding: '3rem', flexDirection: 'column' }}>
                <DialogTitle style={{ textAlign: 'center', color: '#9F5700', fontWeight: 'bold', paddingBottom: '0', paddingTop: '0', marginBottom: '1rem' }}>รายละเอียดบัญชี</DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <IconButton
                        size="small"
                        sx={{ ml: 2 }}
                    >
                        <label htmlFor="contained-button-file">
                            <Input
                                accept="image/*"
                                id="contained-button-file"
                                type="file"
                                onChange={(e) => handleUploadImg(e.target.files[0])}
                            />
                            <Avatar
                                alt={`profile`}
                                src={dataInfo.img ? dataInfo.img : UserAccount}
                                srcSet={dataInfo.img ? dataInfo.img: UserAccount}
                                sx={{ width: 100, height: 100 }}
                            >

                            </Avatar>
                        </label>

                    </IconButton>
                    {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '20%' }} onClick={() => alert("55555")}>

                        {dataInfo.img ? <img src={dataInfo.img} referrerpolicy="no-referrer" style={{ borderRadius: '100%' }} /> : <img src={UserAccount} style={{ borderRadius: '100%' }} />}

                    </div> */}
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', marginLeft: '3rem', flexDirection: 'column' }}>
                        <p>ชื่อ - สกุล: {dataInfo.first_name} {dataInfo.last_name}</p>
                        {/* <p>เลขประจำตัวผู้เสียภาษี: xxxxxxxxx</p>
                        <p>เบอร์โทร: xxxxxxxxx</p> */}
                        <p>E-mail:  {dataInfo.email}</p>
                        {dataInfo.providerId === 'google.com' && <p>บัญชีผู้ใช้งานจาก Google</p>
                            //                 : <Button sx={{
                            //      backgroundColor: '#fff',
                            //      color:'#7C7B7B',
                            //      borderRadius: '2rem',
                            //      fontWeight:'bolder',
                            //      fontSize:'16px',
                            //      boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
                            //         '&:hover': {
                            //             color: '#FF8B00',
                            //             backgroundColor: '#fff',
                            //         }

                            // }}>เปลี่ยนรหัสผ่าน </Button>
                        }


                    </div>
                </div>


            </div>
        </Dialog>

    )
}

export default UserInfo