import firebase from '../config/firebaseApi'
import { collection, query, where, getDocs, doc, setDoc, getDoc, updateDoc, orderBy } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
const PushPinServices = {}

PushPinServices.Create = (urn, dataPushpin) => {
    console.log("Create", urn)
    return new Promise((resolve, reject) => {
        const db = doc(firebase.firestore, "pushpins", dataPushpin.id)
        setDoc(db, dataPushpin)
            .then(response => resolve(response))
            .catch(error => reject(error))
    })
}

PushPinServices.GetAllPushPin = (urn) => {
    return new Promise((resolve,reject)=>{
        const q = query(collection(firebase.firestore, "pushpins"), where("model_ref", "==", JSON.parse(localStorage.getItem('model_detail')).model_ref) );
        getDocs(q).then((response)=>resolve(response)).catch((error)=>reject(error))
    })
}
PushPinServices.AddImg = (uuid, data) => {
    return new Promise((resolve, reject) => {
        const db = doc(firebase.firestore, "images", uuid)
        setDoc(db, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
    })
}
PushPinServices.Update = (data, id) => {

    return new Promise((resolve, reject) => {
        const docRef = doc(firebase.firestore, "pushpins",id);
        updateDoc(docRef, data).then((response) => resolve(response)).catch((error) => reject(error))
    })

}
export default PushPinServices;
