import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PushPinServices from '../../services/pushPinServices';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { set } from 'date-fns';

function AccepCaseList({ data, onSave, onDelete, onStatus }) {
    const [name, setName] = useState("")
    const [qty, setQty] = useState(0)
    const [price, setPrice] = useState(0)
    const handleSave = (row) => {
        if (typeof onSave === 'function') {
            onSave(row)
        }
    }


    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">ลำดับ</TableCell>
                        <TableCell align="center">ชื่ออุปกรณ์</TableCell>
                        <TableCell align="center">จำนวน</TableCell>
                        <TableCell align="center">ราคา</TableCell>
                        {onStatus && <TableCell align="center">Action</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data && data.map((row, idx) => (
                        <ItemTable row={row} idx={idx} onSave={handleSave} onDelete={onDelete} onStatus={onStatus} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
const ItemTable = ({ row, idx, onSave, onDelete, onStatus }) => {
    const [name, setName] = useState("")
    const [qty, setQty] = useState(0)
    const [price, setPrice] = useState(0)
    const [isShow, setIsShow] = useState(true)
    const [isEdit, setIsEdit] = useState(false)
    const handleSave = (row) => {
        if (typeof onSave === 'function') {
            onSave({
                sequent: row.sequent,
                name: name,
                qty: qty,
                price: price,
            })
            setIsShow(false)
        }
    }
    const handleDelete = (row) => {
        if (typeof onDelete === 'function') {
            onDelete(row)

        }
    }
    useEffect(() => {
        setName(row?.name)
        setQty(row?.qty)
        setPrice(row?.price)
        console.log('onStatus', onStatus)
        setIsEdit(onStatus)
    }, [row])
    return (
        <TableRow
            key={row.label}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell align="center" component="th" scope="row">
                {idx + 1}
            </TableCell>
            <TableCell align="center">
                {
                    isEdit ?
                        <TextField 
                        id="outlined-basic" sx={{
                            '& label.Mui-focused': {
                                color: '#FF8B00',
                            },
                            '& .MuiInput-underline:after': {
                                borderBottomColor: '#FF8B00',
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderRadius: '2rem',

                                },
                                '&:hover fieldset': {
                                    borderColor: '#FF8B00',

                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#FF8B00',
                                },
                                borderRadius: '2rem',
                                backgroundColor: 'white',
                            },
                        }} variant="outlined" color='primary'
                        value={name} onChange={(e) => setName(e.target.value)} />
                        :
                        <span>{name}</span>
                }
            </TableCell>
            <TableCell align="center">
                {
                    isEdit ?
                        <TextField 
                        id="outlined-basic" sx={{
                            '& label.Mui-focused': {
                                color: '#FF8B00',
                            },
                            '& .MuiInput-underline:after': {
                                borderBottomColor: '#FF8B00',
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderRadius: '2rem',

                                },
                                '&:hover fieldset': {
                                    borderColor: '#FF8B00',

                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#FF8B00',
                                },
                                borderRadius: '2rem',
                                backgroundColor: 'white',
                            },
                        }} variant="outlined" color='primary'
                        value={qty} onChange={(e) => setQty(e.target.value)} />
                        :
                        <span>{qty}</span>
                }
            </TableCell>
            <TableCell align="center">
                {
                    isEdit ?
                        <TextField 
                        id="outlined-basic" sx={{
                            '& label.Mui-focused': {
                                color: '#FF8B00',
                            },
                            '& .MuiInput-underline:after': {
                                borderBottomColor: '#FF8B00',
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderRadius: '2rem',

                                },
                                '&:hover fieldset': {
                                    borderColor: '#FF8B00',

                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#FF8B00',
                                },
                                borderRadius: '2rem',
                                backgroundColor: 'white',
                            },
                        }} variant="outlined" color='primary'
                        value={price} onChange={(e) => setPrice(e.target.value)} />
                        :
                        <span>{price}</span>
                }
            </TableCell>
            {isEdit ? <React.Fragment>
                <TableCell width={150} align="center">
                    {isShow && <Button variant="contained" color='success' onClick={() => handleSave(row)}>บันทึกข้อมูล</Button>}

                </TableCell>
                <TableCell width={120} align="center">
                    <Button variant="contained" color="error" onClick={() => handleDelete(row)}>ลบข้อมูล</Button>
                </TableCell>
            </React.Fragment> : null}

        </TableRow>
    )
}
export default AccepCaseList