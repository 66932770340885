import React, { useEffect, useState, useContext } from 'react'
import '../../Css/Login.css'
import { Link, useNavigate } from "react-router-dom";
import ManagementUserService from '../../services/managementUserService'
import LoginService from '../../services/loginServices'
import ResetPasswordService from '../../services/resetPassword';
import DialogResetPassword from './dialogResetPassword';
import Swal from 'sweetalert2'
import DialogAddCompany from './dialogAddCompany';

const LoginScreen = () => {
    useEffect(() => {
        localStorage.clear();
    }, [])

    const navigate = useNavigate();
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [onOpenReset, setOnOpenReset] = useState(false)
    const [openAddCompany, setOpenAddCompany] = useState(false)
    const handleLogin = async () => {
        try {
            LoginService.EmailAndPassword(email, password).then((result) => {
                const user = result.user;

                var uid = user.uid
                console.log('uid', user.uid)
                ManagementUserService.GetUserInfoOnce(uid).then((GetUserInfoOnce) => {
                    console.log("user data", GetUserInfoOnce.data())
                    localStorage.setItem("uid", JSON.stringify(
                        {
                            uid: GetUserInfoOnce.data().uid,
                            email: GetUserInfoOnce.data().email,
                            first_name: GetUserInfoOnce.data().first_name,
                            last_name: GetUserInfoOnce.data().last_name,
                            role: GetUserInfoOnce.data().role,
                            company_id: GetUserInfoOnce.data().company_id
                        }
                    ))
                    navigate("/viewer")
                })
            }).catch((error) => {
                return Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'อีเมลหรือรหัสผ่านไม่ถูกต้อง',
                    icon: 'error',
                    confirmButtonColor: '#F7941D',
                    background: '#F7F7F7',
                    confirmButtonText: 'ตกลง'
                })
            })
        } catch (error) {
            alert(error);
        }
    }
    const handleLoginGoogle = (e) => {
        e.preventDefault()
        try {
            LoginService.Google().then((result) => {
                const user = result.user;
                var uid = user.uid
                console.log("result", user)
                localStorage.setItem("accessToken", user.accessToken)
                ManagementUserService.GetUserInfoOnce(uid).then((GetUserInfoOnce) => {
                    if (GetUserInfoOnce.exists()) {
                        localStorage.setItem("uid", JSON.stringify(
                            {
                                uid: GetUserInfoOnce.data().uid,
                                email: GetUserInfoOnce.data().email,
                                first_name: GetUserInfoOnce.data().first_name,
                                last_name: GetUserInfoOnce.data().last_name,
                                role: GetUserInfoOnce.data().role,
                                company_id: GetUserInfoOnce.data().company_id
                            }
                        ))
                        if (JSON.parse(localStorage.getItem("uid")).company_id) {
                            navigate("/viewer")
                        } else {
                            setOpenAddCompany(true)
                        }

                    } else {
                        if (user.displayName.split(" ").length > 1) {
                            var createUser = {
                                first_name: user.displayName.split(" ")[0],
                                last_name: user.displayName.split(" ")[1],
                                email: user.providerData[0].email,
                                img: user.photoURL,
                                uid: user.uid,
                                providerId: result.providerId,
                                role: -1,
                                company_id: '',
                                companyName: ''
                            }
                            localStorage.setItem("uid", JSON.stringify(
                                {
                                    uid: user.uid,
                                    email: user.providerData[0].email,
                                    first_name: user.displayName.split(" ")[0],
                                    last_name: user.displayName.split(" ")[1],
                                    role: -1,
                                    company_id: '',
                                }
                            ))
                        } else {
                            var createUser = {
                                first_name: user.displayName,
                                last_name: '',
                                email: user.providerData[0].email,
                                img: user.photoURL,
                                uid: user.uid,
                                providerId: result.providerId,
                                role: -1,
                                company_id: '',
                                companyName: ''
                            }
                            localStorage.setItem("uid", JSON.stringify(
                                {
                                    uid: user.uid,
                                    email: user.providerData[0].email,
                                    first_name: user.displayName,
                                    last_name: '',
                                    role: -1,
                                    company_id: '',
                                }
                            ))
                        }


                        ManagementUserService.AddUser(uid, createUser).then((response) => {
                            if (JSON.parse(localStorage.getItem("uid")).company_id) {
                                navigate("/viewer")
                            } else {
                                setOpenAddCompany(true)
                            }
                        }).catch((error) => {
                            console.log(error)
                        })
                    }
                })
            })
        } catch (error) {
            alert(error);
        }
    }
    const handleLoginFacebook = () => {
        try {
            LoginService.Facebook().then((result) => {
                const user = result.user;
                var uid = user.uid
                ManagementUserService.GetUserInfoOnce(uid).then((GetUserInfoOnce) => {
                    if (GetUserInfoOnce.exists()) {
                        localStorage.setItem("uid", JSON.stringify(
                            {
                                uid: GetUserInfoOnce.data().uid,
                                email: GetUserInfoOnce.data().email,
                                first_name: GetUserInfoOnce.data().first_name,
                                last_name: GetUserInfoOnce.data().last_name,
                                role: GetUserInfoOnce.data().role,
                                company_id: GetUserInfoOnce.data().company_id
                            }
                        ))
                        navigate("/viewer")
                    } else {
                        var createUser = {
                            first_name: user.displayName.split(" ")[0],
                            last_name: user.displayName.split(" ")[1],
                            email: user.providerData[0].email,
                            uid: user.uid,
                            providerId: result.providerId,
                            role: -1,
                            company_id: '',
                            companyName: ''
                        }
                        localStorage.setItem("uid", JSON.stringify({ uid: createUser.uid, role: createUser.role }))
                        ManagementUserService.AddUser(uid, createUser).then((response) => {
                            navigate("/viewer")
                        }).catch((error) => {
                            console.log(error)
                        })
                    }
                })
            })
        } catch (error) {
            alert(error);
        }

    }
    const handleResetPassword = (email) => {
        ResetPasswordService.RetPassword(email).then((response) => {
            console.log("response", response)
        }).catch((error) => {
            console.log("response", error)
        })
    }
    const handleOpenResetPassword = () => {

        return Swal.fire({
            title: 'รีเซ็ตรหัสผ่าน',
            text: 'กรุณาระบุอีเมลของคุณเพื่อรีเซ็ตรหัสผ่าน',
            input: 'text',
            // inputAttributes: {
            //   autocapitalize: 'off'
            // },
            confirmButtonColor: '#F7941D',
            background: '#F7F7F7',
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ยืนยัน',
            showLoaderOnConfirm: true,
            preConfirm: (email) => {
                return ResetPasswordService.RetPassword(email).then((response) => {
                    console.log("response", response)
                }).catch((error) => {
                    console.log("response", error)
                })
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'ตรวจสอบที่อีเมลของคุณ',
                    showConfirmButton: true,
                    confirmButtonColor: '#F7941D',
                    confirmButtonText: 'รับทราบ',
                    timer: 4200
                })
            }
        }).catch((error) => {
            console.log(error)
        })
        // setOnOpenReset(true)
    }




    return (
        <div className="Stage">
            <div className="Layer">

                <div className="AboveLoginBox">
                    <img src="Icon TIS.png" className="Icon-Logo-tis" alt="TRIDENTLOGO" width="506" />
                    <img src="Icon BIM.png" className="Icon-Logo-bim" alt="BIMLOGO" width="320" />
                </div>

                <div className="LoginBox">
                    <div className="LoginBox-Left">
                        <p className="Text-01"> การเข้าสู่การใช้งาน BIM Database </p>
                        <p className="Text-02"> กรุณากรอกอีเมลและรหัสผ่านเพื่อเข้าสู่การใช้งานบัญชีของคุณ </p>

                        <div className="TextFieldZone">
                            <img src="Icon user.png" className="Icon-Logo" alt="Email" width="28" height="28" />
                            <input className="TextField" type="text" placeholder='กรุณาระบุอีเมล' onChange={(e) => setEmail(e.target.value)} />
                        </div>



                        <div className="Clear" />

                        <div className="TextFieldZone">
                            <img src="Icon passwords.png" className="Icon-Logo" alt="Password" width="28" height="28" />
                            <input className="TextField" type="password" placeholder='กรุณาระบุรหัสผ่าน' onChange={(e) => setPassword(e.target.value)} />

                        </div>


                        <div className="Clear" />



                        <div className="Text-03">

                            <p style={{ color: '#797B7E' }} onClick={handleOpenResetPassword}>หากลืมรหัสผ่าน <span style={{ fontWeight: 'bolder', cursor: 'pointer' }}> คลิกที่นี่ </span></p>

                        </div>

                        <div className="Clear" />

                    </div>
                    <div className="LoginBox-Right">
                        <div className="SocialLoginBox">
                            <div className="Text-04">
                                <p> หรือเข้าใช้งานด้วย... </p>
                            </div>

                            <div className="Clear" />

                            <div className="GoogleButton">
                                <button className="GoogleButton-Button" onClick={handleLoginGoogle}>
                                    <img src="Icon Google.png" className="GoogleButton-Icon" alt="Google-Icon" width="38" />
                                    <div className="GoogleButton-Text"> <p> Google </p> </div>
                                </button>
                            </div>

                            <div className="Clear" />

                            {/* <div className="FacebookButton">
                                <button className="FacebookButton-Button" onClick={handleLoginFacebook}>
                                    <img src="Icon Facebook.png" className="FacebookButton-Icon" alt="Facebook-Icon" width="38" />
                                    <div className="FacebookButton-Text"> <p> Facebook </p> </div>
                                </button>
                            </div> */}

                            <div className="Clear" />

                        </div>
                    </div>
                </div>

                <div className="UnderLoginBox">
                    <button className="LoginButton-Button" onClick={handleLogin}>
                        <p className="LoginButton-Text"> เข้าสู่ระบบ </p>
                    </button>
                    <Link className='linkSign' to="/signup">
                        <button className="RegisterButton-Button" >
                            <p className="RegisterButton-Text" > ลงทะเบียน </p>
                        </button>
                    </Link>

                    {/* <div className="Clear" /> */}
                </div>

                <div className="Credit">
                    <hr className="FootageLine">
                    </hr>
                    <p className="FootageMessage"> Copyright  © 2021,  Trident Intelligence Service </p>
                </div>

            </div>
            <DialogResetPassword open={onOpenReset} onClose={(res) => setOnOpenReset(res)} onSave={handleResetPassword} />
            <DialogAddCompany open={openAddCompany} onClose={(res) => setOpenAddCompany(res)} />
        </div>
    )
}

export default LoginScreen