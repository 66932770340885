import firebase from '../config/firebaseApi'
import {
    collection,
    doc,
    setDoc,
    deleteDoc,
    onSnapshot,
    getDocs,
    addDoc,
    query,
    where,
    updateDoc,
    increment,
    orderBy,
    getDoc
} from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
const ModelDetailServices={}
ModelDetailServices.Add=(data)=>{
    console.log(data.dbId)
    return new Promise((resolve,reject)=>{
        const db = doc(firebase.firestore, "modeldetail","models", JSON.parse(localStorage.getItem("model_detail")).model_ref,data.id)
        setDoc(db, data).then(response => resolve(response)).catch(error => reject(error))
    })
}
ModelDetailServices.Get=(data)=>{
    console.log("Get",data)
    return new Promise((resolve, reject) => {
        const q = query(collection(firebase.firestore, "modeldetail","models", JSON.parse(localStorage.getItem("model_detail")).model_ref),where("dbId","==",data));
      //  const q = doc(firebase.firestore, "modeldetail","models", JSON.parse(localStorage.getItem("model_detail")).model_ref,"id-"+data)
        getDocs(q).then(response => resolve(response)).catch(error => reject(error))
    })

}
ModelDetailServices.Delete=(id)=>{
    return new Promise((resolve, reject) => {
        //const q = query(collection(firebase.firestore, "modeldetail","models", JSON.parse(localStorage.getItem("model_detail")).model_ref),id);
        const q = doc(firebase.firestore, "modeldetail","models", JSON.parse(localStorage.getItem("model_detail")).model_ref,id)
      deleteDoc(q).then(response => resolve(response)).catch(error => reject(error))
    })

}
export default ModelDetailServices