import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import FreeTrialSignup from './components/freeTrial/FreeTrialSignup';
import FreeTrialAdmin from './components/freeTrial/FreeTrialAdmin';
import LoginScreen from "../src/screens/loginScreen/index";
import ManinScreen from "./screens/mainScreen";
import './Css/Menubar.css'
import './App.css';
import SignUp from "./screens/signupScreen";
import { AuthProvider } from "./auth/auth";

const App = () => {
  return (
    <AuthProvider>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LoginScreen />} />
            <Route path="/viewer" element={<ManinScreen />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/free-trial-signup" element={<FreeTrialSignup />} />
            <Route path="/free-trial-admin" element={<FreeTrialAdmin />} />  {/* New route for admin */}
          </Routes>
        </BrowserRouter>
      </div>
    </AuthProvider>
  );
}

export default App;
