import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import '../../Css/NotifyPopup.css';
import UploadModel from '../projectManagement/uploadModel';
import DownloadModel from '../projectManagement/downloadModel';

const DialogProjectManage = ({ open, handleSaveUploadModel, handleClose }) => {
    const [value, setValue] = useState('2');

    const handleClickClose = () => {
        console.log('Close button clicked'); // Check if this logs when the button is clicked
        if (typeof handleClose === 'function') {
            handleClose(false);
        }
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const onSaveUploadModel = (obj) => {
        if (typeof handleSaveUploadModel === "function") {
            handleSaveUploadModel(obj);
        }
    };

    return (
        <Dialog maxWidth="lg" open={open} onClose={handleClickClose}>
            <IconButton
                onClick={handleClickClose}
                sx={{ 
                    position: 'absolute', 
                    right: 8, 
                    top: 8, 
                    color: (theme) => theme.palette.grey[500],
                    zIndex: 1400 // Ensuring it is on top
                }}
                aria-label="close"
            >
                <CloseIcon />
            </IconButton>
            <Box sx={{ width: '100%', typography: 'body1', backgroundColor: '#e5e5e5' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" sx={{
                            '& .MuiTabs-indicator': {
                                backgroundColor: '#FF8B00',
                            },
                            '& .MuiTabs-flexContainer ': {
                                '& .Mui-selected': {
                                    color: '#FF8B00',
                                },
                            }
                        }} >
                            <Tab label="เพิ่มโครงการ" value="2" />
                            <Tab label="ดาวน์โหลดโมเดล" value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="2">
                        <DialogTitle style={{ textAlign: 'center', color: '#9F5700', fontWeight: 'bold', paddingBottom: '0', paddingTop: '0' }}>เพิ่มโครงการ</DialogTitle>
                        <DialogContent key={`dialogworker-dd`}>
                            <UploadModel handleSaveUploadModel={onSaveUploadModel} />
                        </DialogContent>
                    </TabPanel>
                    <TabPanel value="3">
                        <DialogTitle style={{ textAlign: 'center', color: '#9F5700', fontWeight: 'bold', paddingBottom: '0', paddingTop: '0' }}>ดาวน์โหลดโมเดล</DialogTitle>
                        <DialogContent key={`dialogworker-download-model`}>
                            <DownloadModel />
                        </DialogContent>
                    </TabPanel>
                </TabContext>
            </Box>
        </Dialog>
    );
}

export default DialogProjectManage;
