import React, { useEffect, useState } from 'react'
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OAuth from '../../config/autodeskApi'
import QueryModelsService from '../../services/queryModelsService';
import { Button, Grid, TextField } from '@mui/material';
import DownloadModelService from '../../services/downloadModelService';
import Swal from 'sweetalert2'
import LogHistoryService from '../../services/logHistory';
import { bucketName } from "../../config/master";
const DownloadModel = () => {
    const [access_Token, setAccess_Token] = useState("");
    const [expires_in, setExpires_in] = useState("");
    const [tokenModels, setTokenModels] = useState([])
    const [tokenSelect, setTokenSelect] = useState('')

    useEffect(() => {
        fetchToken()
        onfetchModels()
    }, []);
    const onfetchModels = async () => {
        const data = []
        QueryModelsService.GetModelSelect().then((response) => {
            response.forEach((doc) => {
                data.push({ name_model: doc.data().name_model, token: doc.data().token_model, type: doc.data().type_model, model_ref: doc.data().model_ref })
            });
            setTokenModels(data)
        })
    }
    const fetchToken = () => {
        OAuth.GetToken().then((response) => {
            setAccess_Token(response.access_token)
            setExpires_in(response.expires_in)
        })
    };
    const onDownload = () => {
        console.log("responseGetDetail", tokenSelect)
        DownloadModelService.GetDetail(tokenSelect, bucketName, access_Token).then((responseGetDetail) => {
            console.log("responseGetDetail", responseGetDetail)
            DownloadModelService.GetLink(responseGetDetail.data.bucketKey, responseGetDetail.data.objectKey, bucketName, access_Token).then((responseGetLink) => {
                console.log('GetDownload: ', responseGetLink)
                var data = {
                    uid: JSON.parse(localStorage.getItem("uid")).uid,
                    email: JSON.parse(localStorage.getItem("uid")).email,
                    first_name: JSON.parse(localStorage.getItem("uid")).first_name,
                    last_name: JSON.parse(localStorage.getItem("uid")).last_name,
                    time: new Date(),
                    message: "download Model",
                    model_ref: JSON.parse(localStorage.getItem("model_detail")).model_ref,
                    name_model: JSON.parse(localStorage.getItem("model_detail")).name_model
                }

                LogHistoryService.Add(JSON.parse(localStorage.getItem("uid")).company_id, data).then((response) => {
                    response(response)

                }).catch((error) => {
                    console.log(error)
                })
                var url = responseGetLink.data.signedUrl
                window.open(url);
            })
        }).catch((error) => {
            Swal.fire({
                title: 'โปรดเลือกโมเดลก่อน',
                text: 'คุณต้องเลือกโมเดลที่ต้องการดาน์โหลด',
                icon: 'error',
                confirmButtonText: 'รับทราบ'
            })
        })

    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <FormControl fullWidth sx={{

                    '& label.Mui-focused': {
                        color: '#FF8B00',
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: '#FF8B00',
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderRadius: '2rem',

                        },
                        '&:hover fieldset': {
                            borderColor: '#FF8B00',

                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#FF8B00',
                        },
                        borderRadius: '2rem',
                        backgroundColor: 'white',
                    },
                }}>
                    <InputLabel id="demo-multiple-name-label" style={{ color: '#BCBFC0', paddingBottom: "1rem" }}>ค้นหาโมเดลที่ต้องการโหลด</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={tokenSelect}
                        onChange={(e) => setTokenSelect(e.target.value)}
                        MenuProps={MenuProps}
                        style={{ backgroundColor: 'white', borderRadius: '2rem' }}
                    >
                        {tokenModels.map((option) => (
                            <MenuItem key={option.token} value={`${option.model_ref}.${option.type}`} name={option.name_model}>
                                {option.name_model}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} className="mt-3 mb-3">
                <TextField label="หมายเหตุการ download" fullWidth sx={{
                    '& label.Mui-focused': {
                        color: '#FF8B00',
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: '#FF8B00',
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderRadius: '2rem',

                        },
                        '&:hover fieldset': {
                            borderColor: '#FF8B00',

                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#FF8B00',
                        },
                        borderRadius: '2rem',
                        backgroundColor: 'white',
                    },
                }} />
            </Grid>

            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={onDownload} sx={{
                    backgroundColor: '#fff',
                    color: '#7C7B7B',
                    borderRadius: '2rem',
                    fontWeight: 'bolder',
                    fontSize: '16px',
                    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
                    '&:hover': {
                        color: '#FF8B00',
                        backgroundColor: '#fff',
                    }

                }}>บันทึกและโหลดโมเดล</Button>
            </div>


        </Grid>

    )
}

export default DownloadModel