import React, { useState } from 'react';
import { Button, TextField, Grid, Paper, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import firebaseConfig from '../../config/firebaseApi';
import { addDoc, collection } from 'firebase/firestore';

const { firestore } = firebaseConfig;

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: 'auto',  // Center the Paper
}));

const FormContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',  // Full viewport height
}));

function FreeTrialSignup() {
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [lineID, setLineID] = useState("");
    const [name, setName] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const navigate = useNavigate();

    const demoEmail = "userdemo1@gmail.com";
    const demoPassword = "user1234";

    const handleSignup = async () => {
        try {
            await addDoc(collection(firestore, "freeTrialUsers"), {
                Email: email,
                PhoneNumber: phoneNumber,
                LineID: lineID,
                Name: name
            });

            // Show the modal with details
            setOpenModal(true);

        } catch (error) {
            console.error("Error creating free trial account:", error);
            alert(error.message);
        }
    };

    const handleClose = () => {
        setOpenModal(false);
        window.location.href = 'https://tridentintel.net/';
    };

    return (
        <FormContainer maxWidth="sm">
            <Item>
                <Typography variant="h5">Please fill out the form below to enjoy your Free Trial.</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Name"
                            variant="outlined"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Phone Number"
                            variant="outlined"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Line ID ( optional )"
                            variant="outlined"
                            value={lineID}
                            onChange={(e) => setLineID(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Email"
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleSignup}
                        >
                            Sign Up
                        </Button>
                    </Grid>
                </Grid>
            </Item>

            <Dialog open={openModal} onClose={handleClose}>
                <DialogTitle>Free Trial Account Details</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Your free trial account has been created successfully. Here are your login details:
                    </DialogContentText>
                    <DialogContentText style={{ fontWeight: 'bold', color: 'red' }}>
                        Email: {demoEmail}
                    </DialogContentText>
                    <DialogContentText style={{ fontWeight: 'bold', color: 'red' }}>
                        Password: {demoPassword}
                    </DialogContentText>
                    <DialogContentText>
                        Note: These credentials are shared for all free trial users.
                    </DialogContentText>
                    <DialogContentText style={{ marginTop: '20px', fontWeight: 'bold' }}>
                        Please copy or write down the email and password below to login to BIMdatabase Website.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    {/* <Button
                        color="primary"
                        onClick={handleClose}
                        variant="contained"
                    >
                        CLOSE
                    </Button> */}
                    <div>
                        <Button
                            color="primary"
                            onClick={() => window.open('https://drive.google.com/drive/folders/12PdS1h118LIwKtwwWS8inyAsSqk831PW?usp=sharing', '_blank')}
                            variant="contained"
                            style={{ marginRight: '8px' }}
                        >
                            HOW TO USE
                        </Button>
                        <Button
                            color="primary"
                            onClick={() => window.open('https://www.youtube.com/watch?v=tdsOc72r8P0&list=PLQGtu0_K020xl3bsZe-wy41xXfSMe7d5r', '_blank')}
                            variant="contained"
                            style={{ marginRight: '8px' }}
                        >
                            USAGE VIDEO
                        </Button>
                        <Button
                            color="primary"
                            onClick={() => window.open('https://bimdatabase.net/', '_blank')}
                            variant="contained"
                        >
                            CLICK TO WEBSITE
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </FormContainer>
    );
}

export default FreeTrialSignup;
