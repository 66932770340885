import { collection, query, where, getDocs, doc, updateDoc, setDoc, getDoc } from "firebase/firestore";
import firebase from '../config/firebaseApi'

const ManagementModelService = {}

ManagementModelService.GetModel = (selectModel) => {
    return new Promise((resolve, reject) => {
        const q = query(collection(firebase.firestore, "models"), where("token_model", "==", selectModel));
        getDocs(q).then((response) => resolve(response)).catch((error) => reject(error))
    })
}
ManagementModelService.AddScurve = (data) => {
    return new Promise((resolve, reject) => {
        const modelRef = doc(firebase.firestore, "scurves", JSON.parse(localStorage.getItem("model_detail")).model_ref);
        setDoc(modelRef, data).then((response) => resolve(response)).catch((error) => reject(error))
    })
}
ManagementModelService.GetScurve = () => {
    return new Promise((resolve, reject) => {
        const q = query(collection(firebase.firestore, "scurves"), where("model_ref", "==", JSON.parse(localStorage.getItem("model_detail")).model_ref));
        getDocs(q).then(response => resolve(response)).catch(error => reject(error))
    })
}
ManagementModelService.UpdateScurve = (data) => {
    return new Promise((resolve, reject) => {
        const modelRef = doc(firebase.firestore, "scurves", JSON.parse(localStorage.getItem("model_detail")).model_ref);
        updateDoc(modelRef, data).then((response) => resolve(response)).catch((error) => reject(error))
    })
}

ManagementModelService.AddDocs = (data) => {
    return new Promise((resolve, reject) => {
        const modelRef = doc(firebase.firestore, "Docs", JSON.parse(localStorage.getItem("model_detail")).model_ref);
        setDoc(modelRef, data).then((response) => resolve(response)).catch((error) => reject(error))
    })
}
ManagementModelService.GetDocs = () => {
    return new Promise((resolve, reject) => {
        const q = query(collection(firebase.firestore, "Docs"), where("model_ref", "==", JSON.parse(localStorage.getItem("model_detail")).model_ref));
        getDocs(q).then(response => resolve(response)).catch(error => reject(error))
    })
}
ManagementModelService.UpdateDocs = (data) => {
    return new Promise((resolve, reject) => {
        const modelRef = doc(firebase.firestore, "Docs", JSON.parse(localStorage.getItem("model_detail")).model_ref);
        updateDoc(modelRef, data).then((response) => resolve(response)).catch((error) => reject(error))
    })
}
ManagementModelService.UpdateMap = (name_model, data) => {
    console.log('name model',data)
    return new Promise((resolve, reject) => {
        const modelRef = doc(firebase.firestore, "models", name_model);
        updateDoc(modelRef, data).then(response => resolve(response)).catch(error => reject(error))
    })
}
export default ManagementModelService