import axios from "axios";

const DownloadModelService = {}
DownloadModelService.GetDetail = (nameModel,bucket, access_Token) => {
    console.log("nameModel",nameModel)
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `https://developer.api.autodesk.com/oss/v2/buckets/${bucket}/objects/${nameModel}/details`,
            headers: {
                Authorization: `Bearer ${access_Token}`,
                'Content-Type': 'application/json',
                
            },
        }).then(response => resolve(response)).catch(error => reject(error))
    })
}
DownloadModelService.GetLink = (bucketKey, objectKey,bucket, access_Token) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: `https://developer.api.autodesk.com/oss/v2/buckets/${bucketKey}/objects/${objectKey}/signed`,
            headers: {
                Authorization: `Bearer ${access_Token}`,
                'Content-Type': 'application/json;charset=UTF-8',
            },
            data: '{ }',
        }).then(response => resolve(response)).catch(error => reject(error))
    })
}
export default DownloadModelService;