import React, { useState, useEffect } from 'react'
import FullCalendar from '@fullcalendar/react'
import thai from '@fullcalendar/core/locales/th';
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import Swal from 'sweetalert2'
import PushPinServices from '../../services/pushPinServices';
import moment from 'moment'
import 'moment/locale/th'  // without this line it didn't work
moment.locale('th')
function Calendar() {
    const handleDateClick = (arg) => { // bind with an arrow function
        console.log(arg.event.title)
        Swal.fire({
            title: arg.event.title,
            text: arg.event.extendedProps.descripton,
            icon: 'info',
            confirmButtonColor: '#F7941D',
            confirmButtonText: 'รับทราบ'
        })
    }
    const [items, setItems] = useState([])
    const [item, setItem] = useState([])
    const [open, setOpen] = useState(false)
    const [event, setEvent] = useState([])
    useEffect(() => {
        PushPinServices.GetAllPushPin(localStorage.getItem("token")).then((res) => {
            console.log("CheckPushPin", res)
            const data = []
            res.forEach(doc => {
                console.log("CheckPushPinDATA", doc.data())
                var color = ""
                console.log('color',doc.data().isAction)
                if (doc.data().isAction === false) {
                    color = "green"
                } else {
                    if (doc.data().priority === 'ไม่ด่วน') {
                        color = "gray"
                    } else if (doc.data().priority === 'ด่วน') {
                        color = 'orange'
                    } else {
                        color = 'red'
                    }
                }
                data.push(
                    {
                        title: doc.data().label,
                        date: moment(new Date(doc.data().datecreate.seconds * 1000)).format("YYYY-MM-DD"),
                        color: color,
                        extendedProps: { descripton: doc.data().description }
                    })
                // setItems(previous => [...previous, doc.data()])
            });

            console.log('data', data)
            setEvent(data)
        }).catch((error) => {
            console.log(error)
        })
    }, [])
    return (
        <FullCalendar
            locale={thai}
            plugins={[dayGridPlugin, interactionPlugin]}
            themeSystem="bootstrap5"
            initialView="dayGridMonth"
            weekends={true}
            headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth'
            }}
            // dateClick={(e)=>handleDateClick(e)}
            eventClick={(e) => handleDateClick(e)}
            events={event}
        />
    )
}

export default Calendar