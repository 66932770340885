import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
import {getAuth,GoogleAuthProvider,FacebookAuthProvider,signInWithPopup} from 'firebase/auth'
// const firebaseConfig = {
//   apiKey: "AIzaSyAQXO3rF4BdqnZscdTkPmLWxF7qImz-68o",
//   authDomain: "bimiot-98.firebaseapp.com",
//   databaseURL: "https://bimiot-98-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "bimiot-98",
//   storageBucket: "bimiot-98.appspot.com",
//   messagingSenderId: "1006385572183",
//   appId: "1:1006385572183:web:3e3ca718c5c65d94be7612",
//};
const firebaseConfig = {
  apiKey: "AIzaSyAZge_VWH3Ef124xgADKPLdzoAYKwRATDI",
  authDomain: "bimdatabasev5-01.firebaseapp.com",
  projectId: "bimdatabasev5-01",
  storageBucket: "bimdatabasev5-01.appspot.com",
  messagingSenderId: "784920763213",
  appId: "1:784920763213:web:65a4e20f6f10ad9f4c3504"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const authConfig= getAuth(app)
const firestore = getFirestore();
export const providerGoogle = new GoogleAuthProvider();
export const providerFacebook= new FacebookAuthProvider()

export default { app, firestore  };