import React, { useState, useEffect } from 'react'
import OAuth from '../config/autodeskApi'
import ModelPropetryService from '../services/modelPropetryService';
import PushPinServices from '../services/pushPinServices';
import '../Css/Viewer2D.css'
import LogHistoryService from '../services/logHistory';
import Swal from 'sweetalert2'
var Autodesk = window.Autodesk;

const ModelViewer = ({ urn, markup, handlePushPin, modelDetail }) => {
    var PushPinExtensionHandle = null;
    const handleOnLoadSuccess = (PushPinExtensionHandle) => {
        if (typeof handlePushPin === "function") {
            handlePushPin(PushPinExtensionHandle)
        }
        handlefetchPushPin()
    }
    const handlefetchPushPin = async () => {
        if (PushPinExtensionHandle) {
            PushPinServices.GetAllPushPin(urn).then((res) => {
                res.forEach(doc => {
                    if (doc.data().isAction === true) {
                        PushPinExtensionHandle.pushPinManager.createItem({
                            id: doc.data().id,
                            label: doc.data().label,
                            description: doc.data().description,
                            status: doc.data().status,
                            type: doc.data().type,
                            isAction: doc.data().isAction,
                            datecreate: doc.data().datecreate,
                            priority: doc.data().priority,
                            position: doc.data().position
                        })
                    }

                });
            }).catch((error) => {
                console.log(error)
            })
        }
    }
    OAuth.GetToken().then(res => {


        var viewer;
        var options = {
            env: 'AutodeskProduction2',
            api: 'derivativeV2',
            getAccessToken: function (onTokenReady) {
                var token = res.access_token
                var timeInSeconds = res.expires_in;
                onTokenReady(token, timeInSeconds);
            }
        };
        Autodesk.Viewing.Initializer(options, function () {
            Autodesk.Viewing.Document.load(documentId, onDocumentLoadSuccess, onDocumentLoadFailure);
        });

        var documentId = (urn ? urn : "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YmltZGF0YWJhc2UtdjUtMjAyMi0wMy0xMi9tZF8wMy5pZmM=")
        function onDocumentLoadSuccess(viewerDocument) {
            try {
                var geometries = viewerDocument.getRoot().search({ type: 'geometry' })
                if (geometries.length === 0) {

                    return
                }
                var initGeom = geometries[0]
                var viewerDiv = document.getElementById('forgeViewer')
                var config = {
                    extensions: initGeom.extensions() || [
                        'Autodesk.Viewing.MarkupsCore',
                        'Autodesk.Viewing.MarkupsGui',
                        'Autodesk.PropertiesManager',
                        'Autodesk.Viewing.Collaboration',
                        'CustomPropertyPanelExtension',
                        'Autodesk.Sample.Navigator'

                    ],
                }

                viewer = new Autodesk.Viewing.Private.GuiViewer3D(viewerDiv, config)
                var svfUrl = viewerDocument.getViewablePath(initGeom)
                var modelOptions = {
                    sharedPropertyDbPath: viewerDocument.getFullPath(
                        viewerDocument.getRoot().findPropertyDbPath(),
                    ),
                }
                viewer.start(svfUrl, modelOptions, onLoadModelSuccess, onLoadModelError)
            } catch (error) {

            }

        }


        function onLoadModelSuccess() {
            console.log("onloadExtension", true)
            var extensionOptions = {
                hideIssuesButton: false,
                hideRfisButton: true,
                hideFieldIssuesButton: true,
                keepCurrentModels: true
            }
            class CustomPropertyPanelExtension extends Autodesk.Viewing.Extension {
                constructor(viewer, options) {
                    super(viewer, options);
                }

                unload() {
                    return true;
                }
            }

            class CustomPropertyPanel extends Autodesk.Viewing.Extensions.ViewerPropertyPanel {
                constructor(viewer, aaa) {
                    super(viewer, aaa);
                }

                displayProperty(property, parent, options) {

                    const [nameEl, valueEl] = super.displayProperty(property, parent, options);
                    if (property.value.startsWith('https://')) {
                        valueEl.innerHTML = `<a href="${property.value}">Link</a>`;
                    }
                }

                setProperties(props, options) {
                    var data = JSON.parse(localStorage.getItem("detailItem")).data
                    const arr = data
                    if (arr) {
                        arr.forEach(e => {
                            this.addProperty(e.field, e.value, e.catagory);
                        });
                    }
                 
                }

                setAggregatedProperties(props) {
                    var data = JSON.parse(localStorage.getItem("detailItem")).data
                    const arr = data
                    super.setAggregatedProperties(props);
                    if (arr) {
                        arr.forEach(e => {
                            this.addProperty(e.field, e.value, e.catagory);
                        });
                    }

                }
            }
            function onPixelCompareExtensionLoaded(pixelCompareExt) {
                var offsetMode = false;

                function onKeyDown(event) {
                    if (!event.keyCode) return;
                    if ((event.keyCode < 49) || (event.keyCode > 54)) return;

                    if (event.keyCode == 49) {
                        offsetMode = !offsetMode;
                        pixelCompareExt.setChangeOffsetMode(offsetMode);
                    } else
                        pixelCompareExt.setDiffMode(event.keyCode - 49);
                };
                window.addEventListener('keydown', onKeyDown);

                pixelCompareExt.compareModelWithCurrent(options.urn2).then(function (result) {
                    console.log(`compare models ${result ? "successful, yeah" : "failed, boo"}`);
                });
            }
            Autodesk.Viewing.theExtensionManager.registerExtension('CustomPropertyPanelExtension', CustomPropertyPanelExtension);


            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

            class NestedViewerExtension extends Autodesk.Viewing.Extension {
                constructor(viewer, options) {
                    super(viewer, options);
                    options = options || {};
                    this._filter = options.filter || ['2d'];
                    this._crossSelection = !!options.crossSelection;
                    this._group = null;
                    this._button = null;
                    this._panel = null;
                    this._onModelLoaded = this.onModelLoaded.bind(this);
                    this._onSelectionChanged = this.onSelectionChanged.bind(this);
                }

                load() {
                    this.viewer.addEventListener(Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT, this._onModelLoaded);
                    // if (this._crossSelection) {
                    //     this.viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this._onSelectionChanged);
                    // }
                    console.log('NestedViewerExtension has been loaded.');
                    return true;
                }

                unload() {
                    this.viewer.removeEventListener(Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT, this._onModelLoaded);
                    if (this._crossSelection) {
                        this.viewer.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this._onSelectionChanged);
                    }
                    if (this._panel) {
                        this._panel.uninitialize();
                    }
                    // Clean our UI elements if we added any
                    if (this._group) {
                        this._group.removeControl(this._button);
                        if (this._group.getNumberOfControls() === 0) {
                            this.viewer.toolbar.removeControl(this._group);
                        }
                    }
                    console.log('NestedViewerExtension has been unloaded.');
                    return true;
                }

                onModelLoaded() {
                    if (this._panel) {
                        this._panel.urn = this.viewer.model.getData().urn;
                    }
                }

                onSelectionChanged() {
                    if (this._panel) {
                        this._panel.select(this.viewer.getSelection());
                    }
                }

                onToolbarCreated() {
                    this._group = this.viewer.toolbar.getControl('nestedViewerExtensionToolbar');
                    if (!this._group) {
                        this._group = new Autodesk.Viewing.UI.ControlGroup('nestedViewerExtensionToolbar');
                        this.viewer.toolbar.addControl(this._group);
                    }
                    this._button = new Autodesk.Viewing.UI.Button('nestedViewerExtensionButton');
                    this._button.onClick = (ev) => {
                        if (!this._panel) {
                            this._panel = new NestedViewerPanel(this.viewer, this._filter, this._crossSelection);
                            this._panel.urn = this.viewer.model.getData().urn;
                        }
                        if (this._panel.isVisible()) {
                            this._panel.setVisible(false);
                            this._button.removeClass('active');
                        } else {
                            this._panel.setVisible(true);
                            this._button.addClass('active');
                        }
                    };
                    this._button.setToolTip('Nested Viewer');
                    this._button.addClass('nestedViewerExtensionIcon');
                    this._group.addControl(this._button);
                }
            }

            class NestedViewerPanel extends Autodesk.Viewing.UI.DockingPanel {
                constructor(viewer, filter, crossSelection) {
                    super(viewer.container, 'nested-viewer-panel', 'Nested Viewer');
                    this._urn = '';
                    this._parentViewer = viewer;
                    this._filter = filter;
                    this._crossSelection = crossSelection;
                }

                get urn() {
                    return this._urn;
                }

                set urn(value) {
                    if (this._urn !== value) {
                        this._urn = value;
                        this._updateDropdown();
                    }
                }

                initialize() {
                    this.container.style.top = '5em';
                    this.container.style.right = '5em';
                    this.container.style.width = '500px';
                    this.container.style.height = '400px';

                    this.title = this.createTitleBar(this.titleLabel || this.container.id);
                    this.container.appendChild(this.title);

                    this._container = document.createElement('div');
                    this._container.style.position = 'absolute';
                    this._container.style.left = '0';
                    this._container.style.top = '50px';
                    this._container.style.width = '100%';
                    this._container.style.height = '330px'; // 400px - 50px (title bar) - 20px (footer)
                    this.container.appendChild(this._container);

                    this._overlay = document.createElement('div');
                    this._overlay.style.width = '100%';
                    this._overlay.style.height = '100%';
                    this._overlay.style.display = 'none';
                    this._overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
                    this._overlay.style.color = 'white';
                    this._overlay.style.zIndex = '101';
                    this._overlay.style.justifyContent = 'center';
                    this._overlay.style.alignItems = 'center';
                    this._container.appendChild(this._overlay);

                    this._dropdown = document.createElement('select');
                    this._dropdown.style.position = 'absolute';
                    this._dropdown.style.left = '1em';
                    this._dropdown.style.top = '1em';
                    this._dropdown.style.setProperty('z-index', '100');
                    this._dropdown.setAttribute('id', 'nestedViewerExtensionDropdown');
                    this._dropdown.addEventListener('change', this._onDropdownChanged.bind(this))
                    this._dropdown.addEventListener('mousedown', function (ev) { ev.stopPropagation(); }); // prevent DockingPanel from kidnapping clicks on the dropdown
                    this._container.appendChild(this._dropdown);

                    this.initializeMoveHandlers(this.container);
                    this._footer = this.createFooter();
                    this.footerInstance.resizeCallback = (width, height) => {
                        this._container.style.height = `${height - 50 /* title bar */ - 20 /* footer */}px`;
                        if (this._viewer) {
                            this._viewer.resize();
                        }
                    };
                    this.container.appendChild(this._footer);
                }

                setVisible(show) {
                    super.setVisible(show);
                    if (show && !this._viewer) {
                        this._viewer = new Autodesk.Viewing.GuiViewer3D(this._container);
                        this._viewer.start();
                        this._onDropdownChanged();
                        if (this._crossSelection) {
                            this._viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, () => {
                                this._parentViewer.select(this._viewer.getSelection());
                            });
                        }
                    }
                }

                select(dbids) {
                    if (this._viewer) {
                        this._viewer.select(dbids);
                    }
                }

                _updateDropdown() {
                    const onDocumentLoadSuccess = (doc) => {
                        this._manifest = doc;
                        const filterGeom = (geom) => this._filter.indexOf(geom.data.role) !== -1;
                        const geometries = doc.getRoot().search({ type: 'geometry' }).filter(filterGeom);
                        if (geometries.length > 0) {
                            this._overlay.style.display = 'none';
                            this._dropdown.innerHTML = geometries.map(function (geom) {
                                return `<option value="${geom.guid()}">${geom.name()}</option>`;
                            }).join('\n');
                        } else {
                            this._overlay.style.display = 'flex';
                            this._overlay.innerText = 'No viewables found';
                            this._dropdown.innerHTML = '';
                        }
                        this._onDropdownChanged();
                    };
                    const onDocumentLoadFailure = () => {
                        console.error('Could not load document.');
                    };
                    this._dropdown.innerHTML = '';
                    Autodesk.Viewing.Document.load('urn:' + this._urn, onDocumentLoadSuccess, onDocumentLoadFailure);
                }

                _onDropdownChanged() {
                    const guid = this._dropdown.value;
                    if (guid) {
                        this._viewer.loadDocumentNode(this._manifest, this._manifest.getRoot().findByGuid(guid));
                    }
                }
            }

            Autodesk.Viewing.theExtensionManager.registerExtension('NestedViewerExtension', NestedViewerExtension);


            ///////////////////////////////////////////////////////////////////////////


            viewer.loadExtension('Autodesk.Viewing.PixelCompare').then(onPixelCompareExtensionLoaded);
            viewer.loadExtension("NestedViewerExtension", { filter: ["2d"], crossSelection: true })
            viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, function (event) {
                var ext = viewer.getExtension('Autodesk.PropertiesManager')
                ext.setPanel(new CustomPropertyPanel(viewer, "55555"))
                viewer.getProperties(event.dbIdArray[0], function (data) {
                    const dbIds = viewer.getSelection()[0];
                    if (data.externalId !== '') {
                        if (typeof modelDetail === 'function') {
                            modelDetail(data)
                        }
                        console.log("Autodesk.PropertiesManager 555", data)

                    } else {
                        console.log("Autodesk.PropertiesManager", data.properties)
                    }
                })


            })
            viewer
                .loadExtension('Autodesk.BIM360.Extension.PushPin', extensionOptions)
                .then(async function (extension) {
                    PushPinExtensionHandle = extension
                    handleOnLoadSuccess(extension)
                    console.log("PushPinExtensionHandle", PushPinExtensionHandle)
                    PushPinExtensionHandle.pushPinManager.addEventListener(
                        'pushpin.selected',

                        function (e) {

                            console.log("PushPinExtensionHandle Select", e)
                            viewer.fitToView(true)
                        },
                    )
                    PushPinExtensionHandle.pushPinManager.addEventListener(
                        'pushpin.created',
                        function (e) {
                            console.log("createPushpin", e.value.itemData)
                            const dataPin = e.value.itemData
                            console.log("e.value.itemData.status", e.value.itemData.isCreate)
                            if (e.value.itemData.isCreate) {
                                const data = {
                                    datecreate: dataPin.datecreate,
                                    description: dataPin.description,
                                    externalId: dataPin.externalId,
                                    id: dataPin.id,
                                    isAction: dataPin.isAction,
                                    label: dataPin.label,
                                    locationIds: dataPin.locationIds,
                                    objectData: dataPin.objectData,
                                    objectId: dataPin.objectId,
                                    position: { x: dataPin.position.x, y: dataPin.position.y, z: dataPin.position.z },
                                    priority: dataPin.priority,
                                    seedURN: dataPin.seedURN,
                                    status: dataPin.status,
                                    type: dataPin.type,
                                    isCreate: false,
                                    viewerState: dataPin.viewerState,
                                    imgs: dataPin.imgs,
                                    model_ref: dataPin.model_ref
                                }
                                PushPinServices.Create(urn, data).then((response) => {
                                    console.log("PushPinServices.Create", response)
                                    var data = {
                                        uid: JSON.parse(localStorage.getItem("uid")).uid,
                                        email: JSON.parse(localStorage.getItem("uid")).email,
                                        first_name: JSON.parse(localStorage.getItem("uid")).first_name,
                                        last_name: JSON.parse(localStorage.getItem("uid")).last_name,
                                        time: new Date(),
                                        message: "แจ้งซ่อม",
                                        model_ref: JSON.parse(localStorage.getItem("model_detail")).model_ref,
                                        name_model: JSON.parse(localStorage.getItem("model_detail")).name_model
                                    }
                                    LogHistoryService.Add(JSON.parse(localStorage.getItem("uid")).company_id, data).then((response) => {
                                        response(response)

                                    }).catch((error) => {
                                        console.log(error)
                                    })
                                    PushPinExtensionHandle.endCreateItem()
                                    
                                    return Swal.fire({
                                        title: 'กำหนดจุดแจ้งซ่อมสำเร็จ',
                                        icon: 'success',
                                        confirmButtonColor: '#0BB91E ',
                                        background: '#F7F7F7',
                                        confirmButtonText: 'ตกลง'
                                    })
                                }).catch((error) => {
                                    console.log(error)
                                })
                            }
                        },
                    )
                })
        }
        function onDocumentLoadFailure() {
            console.error('Failed fetching Forge manifest');
        }
        function onLoadModelError() {

        }
    })
    return (

        <div id="forgeViewer" style={{ fontFamily: 'sans-serif !important' }} />

    )
}

export default ModelViewer