import React, { useState, useEffect } from 'react'
import Notification from '../images/Notification.png';
import Dashboard from '../images/Dashboard.png';
import ProjectManage from '../images/ProjectManage.png';
import AccessingManage from '../images/AccessingManage.png';
import UserAccount from '../images/UserAccount.png';

import DialogNotiFix from './notifyfixDialog/dialogNotiFix';
import DialogOverAll from './overAllDialog/DialogOverAll';
import DialogProjectManage from './projectManagement/dialogProjectManage';
import DialogPermission from '../components/userManagement/DialogPermission';
import ManagementUserService from '../services/managementUserService'
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';

// import MessageOutlined from '@mui/icons-material/MessageOutlined';

import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';

// import InputLabel from '@mui/material/InputLabel';
import SelectModel from './selectModel';
import UserInfo from './userInfo';
import { authConfig } from '../config/firebaseApi'
import { signOut } from 'firebase/auth'
function Menubar({ handleOnSave, handleSaveUploadModel, handleSaveToken, nameModel,onSubmit }) {
  const [openNotification, setOpenNotification] = useState(false)
  const [openOverAll, setOpenOverAll] = useState(false)
  const [openPermis, setOpenPermis] = useState(false)
  const [openProjectManage, setOpenProjectManage] = useState(false)
  const [data, setData] = useState(null)
  const [openUserInfo, setOpenUserInfo] = useState(false)
  const [tokenSelect, setTokenSelect] = useState("")
  const [uid, setUid] = useState(JSON.parse(localStorage.getItem("uid")).uid)
  const [dataInfo, setDataInfo] = useState([])
  useEffect(() => {
    onfetchUserInfo()
  }, [])
  const onfetchUserInfo = () => {
    ManagementUserService.GetUserInfoOnce(uid).then((response) => {
      if (response.exists()) {

        console.log("dataInfo", response.data())
        setDataInfo(response.data())
      }
    }).catch((error) => {
      console.log(error)
    })
  }
  //Notification
  const handleOpenNoti = () => {
    setOpenNotification(true)
  }
  const handleCloseNoti = () => {
    setOpenNotification(false)
  }
  const handleSaveNoti = (obj) => {
    if (typeof handleOnSave === "function") {
      setOpenNotification(false)
      handleOnSave(obj)
    }
    setData(obj)
  }

  //DialogOverAll
  const handleOpenDialogOverAll = () => {
    setOpenOverAll(true)
  }
  const handleCloseDialogOverAll = () => {
    setOpenOverAll(false)
  }
  const handleSaveDialogOverAll = () => {
    setOpenOverAll(false)
  }


  //DialogProject
  const handleOpenProjectManage = () => {
    setOpenProjectManage(true)
  }
  const onSaveUploadModel = (obj) => {
    if (typeof handleSaveUploadModel === "function") {
      handleSaveUploadModel(obj)
    }
  }


  //DialogPermis
  const handleOpenPermis = () => {
    setOpenPermis(true)
  }
  const handleClosePermis = () => {
    setOpenPermis(false)
  }
  const handleSavePermis = () => {
    setOpenPermis(false)
  }
  const onSaveToken = (value) => {
    if (typeof handleSaveToken === "function") {
      handleSaveToken(value)
    }
  }



  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenUserInfo = () => {
    setOpenUserInfo(true)
  }
  const handleOnSignOut = () => {
    localStorage.setItem("uid", JSON.stringify({ uid: "", role: -1 }))
    signOut(authConfig)
  }
  return (
    <div className='Navbar'>
      <div className='Navbar-items'>
        <div className='Nav-list'>
          <img className='list-menu' src={Notification} width='75px' onClick={handleOpenNoti} />
        </div>
        <div className='Nav-list'>
          <img className='list-menu' src={Dashboard} width='75px' onClick={handleOpenDialogOverAll} />
        </div>
        <div className='Nav-list'>
          <img className='list-menu' src={ProjectManage} width='75px' onClick={handleOpenProjectManage} />
        </div>
        {
          JSON.parse(localStorage.getItem('uid')).role != 1 ?
          <div className='Nav-list'>
            <img className='list-menu' src={AccessingManage} width='75px' onClick={handleOpenPermis} />
          </div>:
          null
        }

      </div>
      <img className='LogoBimMenu' src='Icon BIM.png' />
      <div className='Nav-search'>
        <SelectModel handleChangeToken={onSaveToken} nameModel={nameModel} />
        <Tooltip title="บัญชีผู้ใช้">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <img src={dataInfo.img ? dataInfo.img : UserAccount} style={{ borderRadius: '100%', objectFit: 'cover' }} width='55px' height='55px' referrerpolicy="no-referrer" alt={dataInfo.first_name} />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem disabled>
            ชื่อ-สกุล: {dataInfo.first_name} {dataInfo.last_name}
          </MenuItem>
          <MenuItem disabled>
            E-mail: {dataInfo.email}
          </MenuItem>
          {/* <MenuItem disabled>
            เลขประจำตัวผู้เสียภาษี: xxxxxxxxx
          </MenuItem>
          <MenuItem disabled>
            โทร. 053-943011
          </MenuItem> */}
          <MenuItem onClick={handleOpenUserInfo}>
            รายละเอียดผู้ใช้
          </MenuItem>
          <Divider />

          <MenuItem onClick={handleOnSignOut}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            ออกจากระบบ
          </MenuItem>
        </Menu>

      </div>
      <DialogNotiFix
        open={openNotification}
        handleClose={handleCloseNoti}
        handleSave={handleSaveNoti}
        onSubmit={onSubmit}
      />
      <DialogOverAll
        open={openOverAll}
        handleSave={handleCloseDialogOverAll}
        handleClose={handleSaveDialogOverAll}
      />
      {/* <ModelViewer markup={data} /> */}
      <DialogProjectManage
        open={openProjectManage}
        handleClose={(e) => setOpenProjectManage(e)}
        handleSaveUploadModel={onSaveUploadModel}
      />
      {JSON.parse(localStorage.getItem('uid')).role != 1 ?
        <DialogPermission
          open={openPermis}
          handleClose={handleClosePermis}
          handleSave={handleClosePermis}
        /> :
        null
      }
      <UserInfo openUserInfo={openUserInfo} dataInfo={dataInfo} onUpdate={onfetchUserInfo} onClose={(res) => setOpenUserInfo(res)} />
    </div>
  )
}

export default Menubar