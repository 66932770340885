import { useState } from 'react'
import AccessingManage from '../images/AccessingManage.png';
import DialogChat from './DialogChat';
function NameofModel({ nameModel }) {
  const [openChat, setOpenChat] = useState(false)
  const handleOpenDialogChat = () => {
    setOpenChat(true)
  }
  const handleCloseNoti = () => {
    setOpenChat(false)
  }
  return (
    <div className='footer' style={{ position: 'relative' }}>
      <h2 style={{ color: '#808080', textAlign: 'center', marginTop: '1.5rem', fontWeight: 'bold', cursor: 'default' }}>{nameModel}</h2>
      {
        nameModel !== 'โมเดลเริ่มต้น' &&
        <div className='Chat-menu' style={{ position: 'absolute', top: '-15px', right: '20px', borderRadius: '100%', width: '60px', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={handleOpenDialogChat}>
          <img src={'https://flaticons.net/icon.php?slug_category=mobile-application&slug_icon=chat'} style={{ objectFit: 'cover' }} width='50px' />
        </div>
      }
      {
        JSON.parse(localStorage.getItem("model_detail")) && <DialogChat
          open={openChat}
          handleSave={handleCloseNoti}
          handleClose={handleCloseNoti}
        />
      }



    </div>
  )
}

export default NameofModel