import axios from "axios";
import * as qs from "querystring";

const OAuth = {}

OAuth.GetToken = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      method: "POST",
      url: "https://developer.api.autodesk.com/authentication/v2/token",
      data: qs.stringify({
        // client_id: "TERaVUOlpMJDuAjBGmlCud1Fd7UDXGGn",
        // client_secret: "2AMHpsjZKYAnivF0",
        // client_id: "yfs7ASCiipHdYr2SbHlIB4O741zmm8Fq",
        // client_secret: "UkHz1QTuahTuNfSL",
        // client_id:"8tGHUEOlpu0OzoTGrBVxlb6GKe65VuIR",
        // client_secret:"kS7FvOaYRXcFsYhj",
        // client_id: "KaONrbomQzpmR93RLmJf4H7Q3pBpCWRF",
        // client_secret: "Tq4eieoDpXkq9sy",
        // client_id: "VBfbgAl9cCUuTOp8DgYsNmwI3OwVEkvl",
        // client_secret: "LkTTFGYFYuZfc1SA",
        // client_id: "5bVi4SaweMfKcnfqGVOBbQanNaFo17VwpAwo58a6raNc0pGI",
        // client_secret: "oqvaAtqcnJFGsUE7WstuSDjNRZe7X1C2ZoGkownRZ1RotGcuA21wf4fZnXLpPixC",
        client_id: "jaqG7TLNj7fYExVng7G2JnEwgA9gAT0vFex19zZR6cAAEc0H",
        client_secret: "NYSc4NAC80Hs7dWJpthVAg0mODyG9q7M3hnhPGG2uDGhv6krrhnb1YGlmxp8AWE4",
        grant_type: "client_credentials",
        scope: "data:read data:write data:create bucket:read bucket:create",
      })
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error))
  })

}
export default OAuth;
