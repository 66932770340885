import firebase from '../config/firebaseApi'
import {
    collection,
    doc,
    setDoc,
    deleteDoc,
    onSnapshot,
    getDocs,
    addDoc,
    query,
    where,
    updateDoc,
    increment,
    orderBy,
    getDoc
} from "firebase/firestore";

const DbModelsService = {}
DbModelsService.AddModel = (project_name, name_model, data_model) => {
    return new Promise((resolve, reject) => {
        const db = doc(firebase.firestore, "models", name_model)
        setDoc(db, data_model)
            .then(response => resolve(response))
            .catch(error => reject(error))
    })
}
DbModelsService.AddPermission = (name_model, data_user) => {
    return new Promise((resolve, reject) => {
        const db = doc(firebase.firestore, "models", name_model)
        setDoc(db, data_user)
            .then(response => resolve(response))
            .catch(error => reject(error))
    })
}
DbModelsService.AddPushPin = (project_name, name_model, label_name, data_pushpin) => {
    return new Promise((resolve, reject) => {
        const db = doc(firebase.firestore, "models", project_name, name_model, "pushpin", label_name, "detail")
        setDoc(db, data_pushpin)
            .then(response => resolve(response))
            .catch(error => reject(error))
    })
}
// DbModelsService.GetModel = (project_name, name_model) => {
//     return new Promise((resolve, reject) => {
//         const docRef = query(collection(firebase.firestore, "models", project_name, name_model, "detail"));
//         onSnapshot(docRef, (querySnapshot) => {
//             querySnapshot.forEach((doc) => {
//                 console.log("5555",doc)
//             })
//         })
//     })
// }
export default DbModelsService