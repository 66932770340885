import React, { useState } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
const DialogResetPassword = ({ open, onClose, onSave }) => {
    const [email, setEmail] = useState("")

    const handleClose = () => {
        if (typeof onClose === 'function') {
            onClose(false)
        }
    };
    const handleResetPassword = () => {
        if (typeof onSave === 'function') {
            onSave(email)
        }
    }
    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>กรอกที่อยู่ email</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        กรอก email และกดยืนยันระบบจะทำการส่ง email ไปยังที่อยู่ที่คุณได้กรอก กรุณากด link เพื่อทำการเปลี่ยนรหัสผ่าน
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>ยกเลิก</Button>
                    <Button onClick={handleResetPassword}>ยืนยัน</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DialogResetPassword