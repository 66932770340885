import firebase from '../config/firebaseApi'
import {
    collection,
    doc,
    setDoc,
    deleteDoc,
    onSnapshot,
    getDocs,
    addDoc,
    query,
    where,
    updateDoc,
    increment,
    orderBy,
    getDoc
} from "firebase/firestore";

const CompanyService = {}
CompanyService.Add = (id,data) => {
    return new Promise((resolve, reject) => {
        const db = doc(firebase.firestore, "companys", id)
        setDoc(db, data).then(response => resolve(response)).catch(error => reject(error))
    })
}
CompanyService.GetAll=()=>{
    return new Promise((resolve,reject)=>{
        const q = query(collection(firebase.firestore, "companys"));
        getDocs(q).then(response => resolve(response)).catch(error => reject(error))
    })
}
export default CompanyService
