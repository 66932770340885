import React from 'react'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
const Input = styled('input')({
    display: 'none',
});

function UploadImg({setImgs , pushData}) {

    const setSelectImg = (img) => {

        for (let i = 0; i < img.length; i++) {
            setImgs((previous) => [...previous, { img: URL.createObjectURL(img[i]), name: img[i].name, obj: img[i], id: uuidv4() }]);
        }
        // getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        //     setUrlImg(previous=>[...previous,{uuid:uuid,img:downloadURL}])
        //     console.log('File available at', downloadURL);
        //     // handleOnSaveImg(uuid, downloadURL)
        // });
    }
    

  return (
    <Grid item xs={12} className='d-flex justify-content-between mt-4'>
        <label htmlFor="contained-button-file" >
            <Input accept="image/*" id="contained-button-file" multiple type="file" onChange={(e) => setSelectImg(e.target.files)} />
            <Button fullWidth variant="contained" component="span" sx={{
             backgroundColor: '#fff',
             color:'#7C7B7B',
             borderRadius: '2rem',
             fontWeight:'bolder',
             fontSize:'16px',
                '&:hover': {
                    color: '#FF8B00',
                    backgroundColor: '#fff',
                }
             
        }}>
                อัพโหลดไฟล์
            </Button>
        </label>
        <Button variant="contained" className='px-4' sx={{
             backgroundColor: '#fff',
             color:'#7C7B7B',
             borderRadius: '2rem',
             fontWeight:'bolder',
             fontSize:'16px',
                '&:hover': {
                    color: '#FF8B00',
                    backgroundColor: '#fff',
                }
             
        }} onClick={pushData}>บันทึกข้อมูล</Button>
    </Grid>
  )
}

export default UploadImg